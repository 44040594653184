const state = {
    walletLogin: false,
    provider: false,
    loginMethod: false,
    isWalletConnect: false,
    address: '',
    herotag: '',
    signature: '',
    showPopup: false,
}

const getters = {
  walletLogin: state => {
    return state.walletLogin;
  },
  loginMethod: state => {
    return state.loginMethod;
  },
  isWalletConnect: state => {
    const loggedIn = localStorage.getItem('address');
    if(loggedIn){
      return true   
    }else {
      return state.isWalletConnect;
    }
  },
  address: state => {
    return state.address;
  },
  herotag: state => {
    const herotag = localStorage.getItem('herotag');
    if(herotag.length > 0){
      return herotag.replace('.elrond', '');
    } else {
      return state.address.substr(0, 10) + '...';
    }
  },
  showPopup: state => {
    return state.showPopup;
  }
}

const actions = {
  setProvider({commit }, provider){
    commit('setProvider', provider);
  },
  setLoginMethod({commit }, data){
    commit('setLoginMethod', data);
  },
  login({commit }){
    commit('isWalletConnect', true);
  },
  logout({commit }){
    commit('isWalletConnect', false);
    commit('setLoginMethod', false);
  },
  setAddress({commit }, data){
    commit('setAddress', data);
  },
  setSignature({commit }, data){
    commit('setSignature', data);
  },
  showConnectPopup({commit }, data){
    console.log('showConnectPopup', data);
    commit('showPopup', data);
  },
}

const mutations = {
  walletLogin(state, data) {
    state.walletLogin = data;
  },
  setProvider(state, data) {
    state.provider = data;
  },
  setLoginMethod(state, data) {
    state.loginMethod = data;
  },
  isWalletConnect(state, data) {
    state.isWalletConnect = data;
  },
  setAddress(state, data) {
    state.address = data;
  },
  setSignature(state, data) {
    state.signature = data;
  },
  showPopup(state, data) {
    state.showPopup = data;
  },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};