<template>
    <div class="footer-wrapper">
        <p>All rights reserved. 2021 eEarth</p>
    </div>
</template>

<script>
export default {
    name: "footerSection",
};
</script>

<style>
.footer-wrapper p {
    color: #919191;
    margin: 0;
    font-family: Josefin Sans;
    font-size: 12px;
    line-height: 24px;
    text-align: center;
    letter-spacing: 1.6px;
}

.footer-wrapper {
    position: absolute;
    bottom: 0px;
    width: 100%;
}
</style>
