const state = {
    loading: false,
    value: 'Connect',
}

const getters = {
    connecting: state => {
        return state.loading;
    },
}

const actions = {
    connectTo({ commit }) {
        commit('setConnecting');
    },
}

const mutations = {
    setConnecting(state) {
        state.loading = true;
        state.value = 'Connecting...'
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};