<template>
    <div class="page-container">
        <div class="page-bg">
            <video autoplay muted loop id="myVideo">
                <source src="@/assets/Galaxy01.mp4" type="video/mp4" />
            </video>
        </div>
        <div>
            <img
                class="rank-img"
                src="@/assets/images/eniversum-ranking-title.svg"
                alt="image"
            />
        </div>
        <div class="ranking-info">
            <p class="current-rank">
                Your Current Rank: <span> {{ userRank.place }}</span>
            </p>
            <button class="info-airdrop" v-if="ranking == true" @click="switchRank">
                <p>Info Ticket Airdrop</p>
            </button>
            <button class="info-airdrop" v-if="airdrops == true" @click="switchAirdrop">
                <p>Back to Ranking</p>
            </button>
            <div class="table-wrapper">
                <users v-if="ranking == true" />
                <airdrop v-if="airdrops == true" />
            </div>
        </div>
        <div class="bottom-buy-wrapper">
            <div>
                <p>
                    Qualified for <span>3</span> ‘Airdrop’ Tickets. For more chances, own
                    more Collectibles.
                </p>
                <router-link to="/marketplace"
                    ><button>Buy more Collectibles</button></router-link
                >
            </div>
        </div>
    </div>
</template>

<script>
import Users from "@/components/rank/users.vue";
import Airdrop from "@/components/rank/airdrop.vue";
import { mapActions } from "vuex";

export default {
    name: "Rank",
    components: {
        Users,
        Airdrop,
    },
    data() {
        return {
            ranking: true,
            airdrops: false,
        };
    },
    methods: {
        switchRank() {
            //   if (this.ranking == true && this.airdrops == false) {
            this.ranking = false;
            this.airdrops = true;
            //   }
        },
        switchAirdrop() {
            this.ranking = true;
            this.airdrops = false;
        },
    },
    computed: {
        userRank() {
            return this.$store.getters["user/userRank"];
        },
        ...mapActions("user", ["getUserRank"]),
    },
    created() {
        this.getUserRank;
    },
};
</script>

<style>
@import "../../assets/css/rank.css";

.page-bg {
    position: fixed;
    top: 0px;
    z-index: 0;
}
</style>
