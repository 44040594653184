<template>
    <div class="cards-wrp connect-popup">
        <div class="card-container grey drop-shadow wrapper-connect-card">
            <div class="connect-card">
                <div class="logo-wrapper">
                    <img src="@/assets/images/Eniversum_Logo.png" alt="dark-logo" />
                </div>
                <maiar-connect />
                <div class="popup-close" v-on:click="showConnectPopup(false)">x</div>
            </div>
        </div>
    </div>
</template>

<script>
// import axios from 'axios';

import { mapActions } from "vuex";
// import { router } from "../../_helpers";
import MaiarConnect from "@/components/connect/MaiarConnect.vue";

export default {
    name: "ConnectPopup",
    components: {
        MaiarConnect,
    },
    methods: {
        ...mapActions("erdWallet", ["showConnectPopup"]),
    },
};
</script>

<style scoped>
.connect-popup.cards-wrp {
    position: absolute;
    z-index: 999;
    top: calc(50vh);
    left: calc(50vw - 250px);
}
.close-btn {
    position: absolute;
    top: 0px;
    right: 10px;
    background-color: #000;
    width: 30px;
    height: 30px;
    border-radius: 50%;
}
</style>
