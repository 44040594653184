<template>
  <div>
    <div>
      <img
        class="marketplace-img"
        src="@/assets/images/map/map2.png"
        alt="image"
      />
    </div>
    <div class="title-collectibles">Marketplace</div>
    <market />
  </div>
</template>

<script>
import Market from "@/components/market/Market.vue";

export default {
  name: "Marketplace",
  components: {
    Market,
  },
};
</script>

<style>
@import "../../assets/css/marketplace.css";
</style>