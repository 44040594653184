import Vue from "vue";
import Vuex from 'vuex';
import connect from './modules/connect.js';
import airdrop from './modules/airdrop.js';
import navbar from './modules/navbar.js';
import buttons from './modules/buttons.js';
import login from './modules/login.js';
import cards from './modules/cards.js';
import rank from './modules/rank.js';
import user from './modules/user.js';
// import buy from './modules/buy.js';
import mapCards from './modules/mapCards.js';
import erdWallet from './modules/erdWallet.js';
import introduction from './modules/introduction.js';
import { alert } from './modules/alert';
// import { globalVars } from './modules/globalVars';
// import { authentication } from './authentication.module';
import users from './modules/users';

Vue.use(Vuex);

export const store = new Vuex.Store({
    modules: {
        connect,
        airdrop,
        navbar,
        buttons,
        login,
        alert,
        users,
        cards,
        introduction,
        mapCards,
        erdWallet,
        rank,
        // buy,
        user,
    },
});