const state = {
    isCardShow: false,
    mapCards: false,
}

const getters = {
    isCardShow: state => {
        return state.isCardShow;
    },
    mapCards: state => {
        return state.mapCards;
    }
}

const actions = {

}

const mutations = {
    showCards(state) {
        state.isCardShow = true;
    },
    hideCards(state) {
        state.isCardShow = false;
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};