
import { userService } from '../_services';

export function authHeader() {
    // return authorization header with jwt token
    let user = JSON.parse(localStorage.getItem('user'));
    if (user && user.token) {
        // console.log(user.token);
        return { 'Authorization': 'Bearer ' + user.token };
    } else {
        return {};
    }
}

export function handleResponse(response) {
    console.log(response)
    if (!response.ok) {
        if (response.status === 401) {
            // auto logout if 401 response returned from api
            userService.logout();
            location.reload(true);
        }

        const data = JSON.parse(response.data);
        const error = (data && data.message) || response.statusText;
        return Promise.reject(error);
    }

}