import { userService } from '../../_services';
// import { authHeader } from '../../_helpers/auth-header';
// import axios from 'axios';

// export const users = {
    // namespaced: true,
    const state = {
        all: {},
    }
    const getters = {
    }
    const actions = {
        getAll({ commit }) {
            commit('getAllRequest');

            userService.getAll()
                .then(
                    users => commit('getAllSuccess', users),
                    error => commit('getAllFailure', error)
                );
        },
    }
    const mutations = {
        getAllRequest(state) {
            state.all = { loading: true };
        },
        getAllSuccess(state, users) {
            state.all = { items: users };
        },
        getAllFailure(state, error) {
            state.all = { error };
        }
    }
// }

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};