// import axios from 'axios';
import { userService } from '../../_services';
import { router } from '../../_helpers';
// import { authHeader } from '../_helpers';

// const state = {
//     user_id: null
// }

let user = JSON.parse(localStorage.getItem('user'));
const initialState = user ? { status: { loggedIn: true }, user } : { status: {loggedIn: false}, user: null };


const getters = {
    isloggedIn: 
    () => {
        return initialState.status.loggedIn;
    }
}

const actions = {
    async login({ dispatch, commit }, { username, password }) {
        commit('loginRequest', { username });
        // var userData
        await userService.login(username, password)
            .then(
                user => {
                    commit('loginSuccess', user);
                    router.push('/map');
                },
                error => {
                    commit('loginFailure', error);
                    dispatch('alert/error', error, { root: true });
                }
            ).then( () => {
                console.log('getuserRank');
            });
    },
    isloggedIn(){
        return (initialState.status.loggedIn === true);
    },
    logout({ commit }) {
        userService.logout();
        commit('logout');
    },

}

const mutations = {
    loginRequest(state, user) {
        state.status = { loggingIn: true };
        state.user = user;
    },
    loginSuccess(state, user) {
        state.status = { loggedIn: true };
        state.user = user;
    },
    loginFailure(state) {
        state.status = {};
        state.user = null;
    },
    logout(state) {
        state.status = {};
        state.user = null;
        if(window.location.pathname != '/login'){
            window.location.href = '/login';
        }
    },
    setUserData(state, userdata) {
        state.userdata = userdata;
    }
}

export default {
    namespaced: true,
    // rootState,
    state: initialState,
    getters,
    actions,
    mutations,
};