<template>
    <div>
        <ul class="table-header">
            <li style="width: 10%">No.</li>
            <li style="width: 22.5%">User</li>
            <li style="width: 22.5%">No. of Tickets</li>
            <li style="width: 22.5%">No. of Cards</li>
            <li style="width: 22.5%">Points</li>
        </ul>
        <div class="list-wrapper">
            <ul class="table-body" v-for="rank in ranks" :key="rank.id">
                <li style="width: 10%">{{ rank.place }}</li>
                <li class="name-field" style="width: 22.5%" :style="getColor(rank.id)">
                    {{ rank.username }}
                </li>
                <li style="width: 22.5%">12</li>
                <li style="width: 22.5%">{{ rank.total_active_cards }}</li>
                <li class="points-field" style="width: 22.5%">
                    {{ setDecimal(rank.score) }}
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
import { mapActions } from "vuex";
import colors from "@/json/colors.json";

export default {
    name: "Users",
    data() {
        return {};
    },
    methods: {
        setDecimal(value) {
            const decimalValue = +value;
            return decimalValue.toFixed(2);
        },
        getColor(value) {
            var color = colors.find(({ id }) => id == value);
            return `color:${color.color}`;
        },
    },
    computed: {
        ranks() {
            return this.$store.getters["rank/rank"];
        },
        ...mapActions("rank", ["getRank"]),
        decimal() {
            return false;
        },
    },
    created() {
        this.getRank;
    },
};
</script>
