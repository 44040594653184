const state = {
    isNavOpen: false,
    collectibles: false,
    rank: false,
    nft: false,
    title: '',
}

const getters = {
    succes: state => {
        return state.isNavOpen;
    },
    isCollectibles: state => {
        return state.collectibles;
    },
    isRank: state => {
        return state.rank;
    },
    isNft: state => {
        return state.nft;
    },
    Title: state => {
        return state.title;
    }
}

const actions = {

}

const mutations = {
    toggleNav(state) {
        state.isNavOpen = !state.isNavOpen;
        state.collectibles = false;
        state.rank = false;
        state.nft = false;
        state.title = '';
    },
    isCollectibles(state) {
        state.collectibles = true;
        state.isNavOpen = false;
        state.title = 'My Collectibles';

    },
    isRank(state) {
        state.rank = true;
        state.isNavOpen = false;
        state.title = 'Rank';
    },
    isNft(state) {
        state.nft = true;
        state.isNavOpen = false;
        state.title = 'Buy NFTs';
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};