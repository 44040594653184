import axios from 'axios';
import { authHeader } from '../../_helpers/auth-header';
// import moment from "moment"

const state = {
    nextAirDrop: '',
}

const getters = {
    nextAirDrop: state => {
        return state.nextAirDrop;
    },
}

const actions = {
    async checkNextAirDrop({ commit }) {
        var date = [];
        const curentdate = Math.round(+new Date()/1000);
        await axios
            .get('https://178.18.251.150/api/airdrop/next', {
                headers: authHeader()
            })
            .then(response => response.data)
            .then(object => {
                date = object.success[0];
            })
            .catch(error => console.log(error))
        if(date){
            console.log('Next airdrop ', date)
            console.log('Next airdrop in ', date.endDate)
            const dif_time = (date.endDate - curentdate)
            commit('setNextAirDrop', dif_time);
        }
    },
}

const mutations = {
    setNextAirDrop(state, date) {
        state.nextAirDrop = date;
        console.log(date)
    //   if(state.nextAirDrop > 0) {
    //     setTimeout(() => {
    //         state.nextAirDrop -= 1
    //         this.countDownTimer()
    //     }, 1000)
    // }
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};