<template>
  <div>
    <div class="own-piece-1">
      <h2>Own a piece of Earth</h2>
      <p>Be the owner of your favourite country, capital or landmark.</p>
      <p>
        - eEarth is a virtual world built on Elrond’s blockchain where users can
        collect countries, capitals or landmarks.
      </p>
      <p>- Earn points to qualify for the daily airdrop by owning a country.</p>
      <p>- A random Capital or Landmark will be given every 24h.</p>
      <p>
        - Each Country, Capital and Landmark will be dropped as a card. Each day
        random countries will be dropped on map. Stay tuned to be the first
        owner.
      </p>
      <button class="read-guide-btn" @click="readGuide()">READ THE FULL GUIDE</button>
    </div>
  </div>
</template>

<script>
// import { mapGetters } from "vuex";

export default {
  name: "FirstStep",
  props: ['readGuide'],
  data() {
    return {
      openGuide: true,
    };
  },
  methods: {
    closeIntroduction() {
      return (this.openGuide = false);
    },
    // readGuide() {
    //   this.guideOne = false;
    // },
  },
  computed: {
    readFull() {
      return this.$store.getters["introduction/read"];
    },
    // ...mapGetters('introduction', ['read']),
  },
  // props: {
  //   guideOne: {
  //     type: Boolean,
  //     required: true,
  //   },
  // },
};
</script>

<style>
@import "../../assets/css/introduction.css";
@import "../../assets/css/responsive.css";
</style>