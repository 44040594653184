<template>
    <div>
        <header-desktop></header-desktop>
        <header-mobile></header-mobile>
        <ConnectPopup v-if="showPopup"></ConnectPopup>
    </div>
</template>

<script>
import { walletConnect } from "@/_helpers";
import headerDesktop from "@/components/layouts/headerSection.vue";
import headerMobile from "@/components/layouts/headerMobile.vue";
import ConnectPopup from "@/components/connect/ConnectPopup.vue";

import { mapActions, mapGetters } from "vuex";

export default {
    name: "mainHeader",
    components: {
        headerDesktop,
        headerMobile,
        ConnectPopup,
    },
    methods: {
        ...mapActions("erdWallet", ["showConnectPopup", "setAddress"]),
        ...mapActions("user", ["getUserCards"]),
    },
    computed: {
        ...mapGetters("erdWallet", ["showPopup", "isWalletConnect"]),
    },
    created() {
        walletConnect.useGetAddress().then((data) => {
            // console.log("addresss", data);
            this.setAddress(data);
        });
        this.getUserCards();
    },
};
</script>
