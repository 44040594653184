<template>
  <div>
    <div class="coll-img-wrapper">
      <img class="collectibles-img" src="@/assets/images/map/map2.png" alt="image" />
    </div>
    <coll class="collectibles-wrapp" />
  </div>
</template>

<script>
import Coll from "@/components/collectibles/Collectibles.vue";

export default {
  name: "Collectibles",
  components: {
    Coll,
  },
};
</script>

<style>

.collectibles-img {
    width: 100%;
}

</style>