<template>
  <div class="mobile-header" v-if="windowWidth < 768">
    <router-link to="/">
      <div class="logo">
        <img src="@/assets/images/Eniversum_Logo.png" alt="eEarth" />
      </div>
    </router-link>
    <div @click="burger">
      <div id="burger" class="burger-button" :class="{ active: isActive }">
        <span class="burger-bar burger-bar--1"></span>
        <span class="burger-bar burger-bar--2"></span>
        <span class="burger-bar burger-bar--3"></span>
      </div>
    </div>
    <div class="header" :class="{ 'show-menu': isActive }">
      <div class="connect-wrapper">
        <button class="connect-btn connect-maiar-m">
          Connect Maiar
          <!-- <button class="connect-btn" @click="burger">Connect Maiar -->
          <!-- <img src="@/assets/images/link1.png"> -->
          <!-- <p class="connect-parag">Connect Maiar</p> -->

          <!-- <div id="burger" class="burger-button" :class="{ active: isActive }">
            <span class="burger-bar burger-bar--1"></span>
            <span class="burger-bar burger-bar--2"></span>
            <span class="burger-bar burger-bar--3"></span>
          </div> -->

          <!-- <transition name="slide">
            <ul class="submenu-right-wrapper" :class="{ opened: isActive }">
              <li>Guide</li>
              <li>Change Username</li>
              <li>Communities</li>
            </ul>
          </transition> -->
        </button>
      </div>
      <nav id="navbar">
        <ul class="main-menu">
          <router-link to="/">
            <li v-if="isMenuExpanded" @click="closeNavBar()">
              Home
            </li></router-link
          >
          <router-link to="/collectibles"
            ><li v-if="isMenuExpanded" @click="closeNavBar()">
              MyCollectibles
            </li></router-link
          >
          <router-link to="/map"
            ><li v-if="isMenuExpanded" @click="closeNavBar()">
              Map
            </li></router-link
          >
          <router-link to="/rank"
            ><li v-if="isMenuExpanded" @click="closeNavBar()">
              Rank
            </li></router-link
          >
          <li class="arrow-down" v-if="isMenuExpanded">
            Marketplace
            <ul class="droptown-content">
              <router-link to="/marketplace"
                ><li v-if="isMenuExpanded" @click="closeNavBar()">
                  ALL NFTs
                </li></router-link
              >
              <router-link
                :to="{
                  name: 'Subcategory',
                  params: { subcategory: 'palladium' },
                }"
                ><li
                  v-if="isMenuExpanded"
                  @click="closeNavBar()"
                  class="palladium"
                >
                  PALLADIUM
                </li></router-link
              >
              <router-link
                :to="{
                  name: 'Subcategory',
                  params: { subcategory: 'platinium' },
                }"
                ><li
                  v-if="isMenuExpanded"
                  @click="closeNavBar()"
                  class="platinum"
                >
                  PLATINIUM
                </li></router-link
              >
              <router-link
                :to="{ name: 'Subcategory', params: { subcategory: 'gold' } }"
                ><li v-if="isMenuExpanded" @click="closeNavBar()" class="gold">
                  GOLD
                </li></router-link
              >
              <router-link
                :to="{ name: 'Subcategory', params: { subcategory: 'silver' } }"
                ><li
                  v-if="isMenuExpanded"
                  @click="closeNavBar()"
                  class="silver"
                >
                  SILVER
                </li></router-link
              >
              <router-link
                :to="{ name: 'Subcategory', params: { subcategory: 'bronze' } }"
                ><li
                  v-if="isMenuExpanded"
                  @click="closeNavBar()"
                  class="bronze"
                >
                  BRONZE
                </li></router-link
              >
            </ul>
          </li>
        </ul>
      </nav>
      <ul class="submenu-right-wrapper">
        <router-link to="/"><li class="mobile-guide">Guide</li></router-link>
        <li>Change Username</li>
        <li class="mobile-communities">Communities</li>
        <li>Follow us on Twitter</li>
      </ul>
      <!-- <div class="logout botton" v-if="windowWidth > 768">
            <button v-on:click="logout" class="black"><span>Cryptoman (erdlhz..vag36)</span><i class="logout-icon"></i></button>
        </div> -->

      <!-- <div class="submenu-right-wrapper"></div> -->
    </div>
  </div>
</template>

<script>
export default {
  name: "headerMobile",
  data() {
    return {
      windowWidth: window.innerWidth,
      isActive: false,
      isMenuExpanded: true,
    };
  },
  methods: {
    burger() {
      if (this.isActive == false) {
        return (this.isActive = true);
      } else {
        return (this.isActive = false);
      }
    },
    closeNavBar() {
      this.isActive = false;
    },
  },
};
</script>

<style>
@import "../../assets/css/header.css";
@import "../../assets/css/responsive.css";
</style>