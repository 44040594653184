<template>
    <div class="maiar-login unlock-page" v-if="isWalletConnect">
        <div>
            <p class="wallet-address">
                {{ address }}
            </p>
        </div>
        <p class="disconnect-wrapper">
            <button
                v-on:click="WalletLogout"
                class="disconnect-maiar-header disconnect-btn first-step-btn"
            >
                Disconect Wallet
            </button>
        </p>
    </div>
    <div class="maiar-login logedin unlock-page" v-else>
        <div v-if="loginMethod == 'walletconnect'" class="walletconnect">
            <div class="maiar-login-qr">
                <qrcode-vue :value="wcUri" size="200" level="H"></qrcode-vue>
            </div>
            <h1>Maiar Login</h1>
            <p>Scan the QR code using Maiar</p>
            <p>Or copy and paste wc uri:</p>
            <p class="maiar-wc">{{ wcUri }}</p>
        </div>
        <div v-else>
            <span class="connect-wrapper disable">
                <div class="btn btn-unlock btn-block">
                    <div class="d-flex justify-content-between align-items-center">
                        <div class="d-flex flex-row method disable">
                            <div class="title">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="24"
                                    height="20"
                                    viewBox="0 0 24 21"
                                    class="maiar-symbol"
                                >
                                    <g transform="translate(11024 -18800.5)">
                                        <path
                                            class="cls-maiar-extension-1"
                                            d="M21.619-15H3.75A.75.75,0,0,1,3-15.75a.75.75,0,0,1,.75-.75h18a.75.75,0,0,0,.75-.75,2.25,2.25,0,0,0-2.25-2.25H3a3,3,0,0,0-3,3v15a3,3,0,0,0,3,3H21.619A2.321,2.321,0,0,0,24-.75v-12A2.321,2.321,0,0,0,21.619-15Z"
                                            transform="translate(-11024 18820)"
                                        ></path>
                                        <g transform="translate(-11017.25 18809.002)">
                                            <g transform="translate(0 0)">
                                                <path
                                                    class="cls-maiar-extension-2"
                                                    d="M2.13,6.377l.007-.009L0,8.5V0L4.249,4.254,8.5,0V8.5L6.375,6.377,4.25,8.5Z"
                                                    transform="translate(0 0)"
                                                ></path>
                                            </g>
                                        </g>
                                        <circle
                                            class="cls-maiar-extension-2"
                                            cx="1.314"
                                            cy="1.314"
                                            r="1.314"
                                            transform="translate(-11004.771 18812.314)"
                                        ></circle>
                                    </g>
                                </svg>
                                Maiar DeFi Wallet
                                <span>soon...</span>
                            </div>
                        </div>
                        <!-- <svg
                            aria-hidden="true"
                            focusable="false"
                            data-prefix="fas"
                            data-icon="arrow-right"
                            class="svg-inline--fa fa-arrow-right fa-w-14 arrow"
                            role="img"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 448 512"
                        >
                            <path
                                fill="currentColor"
                                d="M190.5 66.9l22.2-22.2c9.4-9.4 24.6-9.4 33.9 0L441 239c9.4 9.4 9.4 24.6 0 33.9L246.6 467.3c-9.4 9.4-24.6 9.4-33.9 0l-22.2-22.2c-9.5-9.5-9.3-25 .4-34.3L311.4 296H24c-13.3 0-24-10.7-24-24v-32c0-13.3 10.7-24 24-24h287.4L190.9 101.2c-9.8-9.3-10-24.8-.4-34.3z"
                            ></path>
                        </svg> -->
                    </div>
                </div>
            </span>
            <span>
                <div class="btn btn-unlock btn-block" v-on:click="WalletLogin">
                    <div class="d-flex justify-content-between align-items-center">
                        <div class="d-flex flex-row method">
                            <div class="title d-flex align-items-center">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="17.5"
                                    height="20"
                                    viewBox="0 0 17.5 28"
                                    class="maiar-symbol maiar-app"
                                >
                                    <g transform="translate(11007 -18884.001)">
                                        <path
                                            class="cls-maiar-app-1"
                                            d="M14.875-21H2.625A2.626,2.626,0,0,0,0-18.375V4.375A2.626,2.626,0,0,0,2.625,7h12.25A2.626,2.626,0,0,0,17.5,4.375v-22.75A2.626,2.626,0,0,0,14.875-21Z"
                                            transform="translate(-11007 18905)"
                                        ></path>
                                        <g transform="translate(-11002.75 18892.168)">
                                            <g transform="translate(0 0)">
                                                <path
                                                    class="cls-maiar-app-2"
                                                    d="M2.255,6.752l.008-.01L0,9V0L4.5,4.5,9,0V9L6.75,6.752,4.5,9Z"
                                                    transform="translate(0 0)"
                                                ></path>
                                            </g>
                                        </g>
                                        <circle
                                            class="cls-maiar-app-2"
                                            cx="1.5"
                                            cy="1.5"
                                            r="1.5"
                                            transform="translate(-10999.75 18906.498)"
                                        ></circle>
                                    </g></svg
                                >Maiar App
                            </div>
                        </div>
                        <svg
                            aria-hidden="true"
                            focusable="false"
                            data-prefix="fas"
                            data-icon="arrow-right"
                            class="svg-inline--fa fa-arrow-right fa-w-14 arrow"
                            role="img"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 448 512"
                        >
                            <path
                                fill="currentColor"
                                d="M190.5 66.9l22.2-22.2c9.4-9.4 24.6-9.4 33.9 0L441 239c9.4 9.4 9.4 24.6 0 33.9L246.6 467.3c-9.4 9.4-24.6 9.4-33.9 0l-22.2-22.2c-9.5-9.5-9.3-25 .4-34.3L311.4 296H24c-13.3 0-24-10.7-24-24v-32c0-13.3 10.7-24 24-24h287.4L190.9 101.2c-9.8-9.3-10-24.8-.4-34.3z"
                            ></path>
                        </svg>
                    </div>
                </div>
            </span>
            <span class="disable">
                <div class="btn btn-unlock btn-block">
                    <div class="d-flex justify-content-between align-items-center">
                        <div class="d-flex flex-row method ledger">
                            <div class="title">
                                <svg
                                    width="42"
                                    height="20"
                                    viewBox="0 0 42 42"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                    class="elrond-symbol body"
                                >
                                    <path
                                        d="M34.4876 0.0413742H15.7717V25.323H41.0412V6.58293C41.0412 2.9933 38.091 0.0413742 34.5081 0.0413742C34.5019 0.0413742 34.4937 0.0413742 34.4876 0.0413742V0.0413742Z"
                                        fill="#142533"
                                    ></path>
                                    <path
                                        d="M9.93712 0.0413742H6.71166C3.12874 0.0413742 0.178574 2.99536 0.178574 6.58293V9.81256H9.93712V0.0413742Z"
                                        fill="#142533"
                                    ></path>
                                    <path
                                        d="M0.178574 15.6547H9.93712V25.4259H0.178574V15.6547Z"
                                        fill="#142533"
                                    ></path>
                                    <path
                                        d="M31.3648 40.9569H34.5903C38.1753 40.9569 41.1234 38.0029 41.1234 34.4153C41.1234 34.4092 41.1234 34.401 41.1234 34.3948V31.268H31.3648V40.9569Z"
                                        fill="#142533"
                                    ></path>
                                    <path
                                        d="M15.7717 31.268H25.5302V41.0392H15.7717V31.268Z"
                                        fill="#142533"
                                    ></path>
                                    <path
                                        d="M0.178574 31.268V34.4976C0.178574 38.0852 3.12874 41.0392 6.71166 41.0392H9.93712V31.268H0.178574Z"
                                        fill="#142533"
                                    ></path></svg
                                >Ledger
                                <span>soon...</span>
                            </div>
                        </div>
                        <!-- <svg
                            aria-hidden="true"
                            focusable="false"
                            data-prefix="fas"
                            data-icon="arrow-right"
                            class="svg-inline--fa fa-arrow-right fa-w-14 arrow"
                            role="img"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 448 512"
                        >
                            <path
                                fill="currentColor"
                                d="M190.5 66.9l22.2-22.2c9.4-9.4 24.6-9.4 33.9 0L441 239c9.4 9.4 9.4 24.6 0 33.9L246.6 467.3c-9.4 9.4-24.6 9.4-33.9 0l-22.2-22.2c-9.5-9.5-9.3-25 .4-34.3L311.4 296H24c-13.3 0-24-10.7-24-24v-32c0-13.3 10.7-24 24-24h287.4L190.9 101.2c-9.8-9.3-10-24.8-.4-34.3z"
                            ></path>
                        </svg> -->
                    </div>
                </div>
            </span>
            <span>
                <div class="btn btn-unlock btn-block" v-on:click="WebWalletLogin">
                    <div class="d-flex justify-content-between align-items-center">
                        <div class="d-flex flex-row method">
                            <div class="title">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="27"
                                    height="24"
                                    viewBox="0 0 27 24"
                                    class="elrond-symbol body"
                                >
                                    <g transform="translate(11028 -19030)">
                                        <path
                                            class="cls-webwallet-1"
                                            d="M24.75-21H2.25A2.251,2.251,0,0,0,0-18.75v13.5A2.251,2.251,0,0,0,2.25-3h22.5A2.251,2.251,0,0,0,27-5.25v-13.5A2.251,2.251,0,0,0,24.75-21ZM22.5,1.875A1.122,1.122,0,0,0,21.375.75H16.753l-.872-2.616a.56.56,0,0,0-.534-.384H11.658a.56.56,0,0,0-.534.384L10.252.75H5.625a1.125,1.125,0,0,0,0,2.25h15.75A1.122,1.122,0,0,0,22.5,1.875Z"
                                            transform="translate(-11028 19051)"
                                        ></path>
                                        <g transform="translate(-11019.5 19034)">
                                            <path
                                                class="cls-webwallet-2"
                                                d="M360.895,396.416a.164.164,0,0,1-.059-.129.16.16,0,0,1,.059-.129.213.213,0,0,1,.28,0,.164.164,0,0,1,.059.129.16.16,0,0,1-.059.129.212.212,0,0,1-.28,0"
                                                transform="translate(-352.894 -394.433)"
                                            ></path>
                                            <path
                                                class="cls-webwallet-2"
                                                d="M353.313,404.289a.207.207,0,0,1-.05-.129.19.19,0,0,1,.19-.19.157.157,0,0,1,.129.059.206.206,0,0,1,.05.129.179.179,0,0,1-.05.129.206.206,0,0,1-.129.05.2.2,0,0,1-.14-.048"
                                                transform="translate(-345.813 -401.786)"
                                            ></path>
                                            <path
                                                class="cls-webwallet-2"
                                                d="M344.848,412.383a.187.187,0,0,1,0-.26h0a.2.2,0,0,1,.268,0,.187.187,0,0,1,0,.26.164.164,0,0,1-.129.059.179.179,0,0,1-.14-.059"
                                                transform="translate(-337.896 -409.36)"
                                            ></path>
                                            <path
                                                class="cls-webwallet-2"
                                                d="M336.759,420.355a.187.187,0,0,1-.011-.26l.011-.011a.212.212,0,0,1,.28,0,.187.187,0,0,1,.011.26l-.011.011a.219.219,0,0,1-.28,0"
                                                transform="translate(-330.327 -416.804)"
                                            ></path>
                                            <path
                                                class="cls-webwallet-2"
                                                d="M329.052,428.706a.164.164,0,0,1-.059-.129.179.179,0,0,1,.19-.179.164.164,0,0,1,.12.05.157.157,0,0,1,.05.129.19.19,0,0,1-.19.19.318.318,0,0,1-.112-.062"
                                                transform="translate(-323.12 -424.626)"
                                            ></path>
                                            <path
                                                class="cls-webwallet-2"
                                                d="M320.516,436.64a.157.157,0,0,1,.059-.129.187.187,0,0,1,.268.26.187.187,0,0,1-.26,0A.178.178,0,0,1,320.516,436.64Z"
                                                transform="translate(-315.193 -432.169)"
                                            ></path>
                                            <path
                                                class="cls-webwallet-2"
                                                d="M304.875,452.357a.143.143,0,0,1-.05-.129.179.179,0,0,1,.05-.129.2.2,0,0,1,.268,0,.189.189,0,0,1,.05.129.161.161,0,0,1-.059.129.165.165,0,0,1-.129.059.157.157,0,0,1-.131-.059"
                                                transform="translate(-300.519 -446.74)"
                                            ></path>
                                            <path
                                                class="cls-webwallet-2"
                                                d="M296.3,460.533a.165.165,0,0,1-.059-.129.173.173,0,0,1,.059-.129.228.228,0,0,1,.291,0,.165.165,0,0,1,.059.129.173.173,0,0,1-.059.129.2.2,0,0,1-.14.059.364.364,0,0,1-.151-.059"
                                                transform="translate(-292.5 -454.384)"
                                            ></path>
                                            <path
                                                class="cls-webwallet-2"
                                                d="M288.735,468.5a.19.19,0,1,1,.268,0,.2.2,0,0,1-.268,0"
                                                transform="translate(-285.428 -461.828)"
                                            ></path>
                                            <path
                                                class="cls-webwallet-2"
                                                d="M280.641,476.539a.177.177,0,0,1-.059-.129.2.2,0,0,1,.19-.19.179.179,0,0,1,.129.05.206.206,0,0,1,.05.129.184.184,0,0,1-.05.129.207.207,0,0,1-.129.05.126.126,0,0,1-.131-.039"
                                                transform="translate(-277.854 -469.342)"
                                            ></path>
                                            <path
                                                class="cls-webwallet-2"
                                                d="M272.207,484.8a.164.164,0,0,1-.059-.129.157.157,0,0,1,.059-.129.212.212,0,0,1,.28,0,.164.164,0,0,1,.059.129.157.157,0,0,1-.059.129.213.213,0,0,1-.28,0"
                                                transform="translate(-269.967 -477.078)"
                                            ></path>
                                            <path
                                                class="cls-webwallet-2"
                                                d="M264.594,492.646a.2.2,0,0,1,0-.268h0a.188.188,0,0,1,.26,0,.2.2,0,0,1,0,.268.188.188,0,0,1-.26,0"
                                                transform="translate(-262.856 -484.401)"
                                            ></path>
                                            <path
                                                class="cls-webwallet-2"
                                                d="M264.643,396.285a.206.206,0,0,0,.05-.129.19.19,0,0,0-.19-.19.179.179,0,0,0-.129.05.164.164,0,0,0-.059.129.157.157,0,0,0,.059.129.188.188,0,0,0,.268.011"
                                                transform="translate(-262.644 -394.302)"
                                            ></path>
                                            <path
                                                class="cls-webwallet-2"
                                                d="M272.486,404.419a.164.164,0,0,0,.059-.129.157.157,0,0,0-.059-.129.213.213,0,0,0-.28,0,.164.164,0,0,0-.059.129.157.157,0,0,0,.059.129.212.212,0,0,0,.28,0"
                                                transform="translate(-269.967 -401.917)"
                                            ></path>
                                            <path
                                                class="cls-webwallet-2"
                                                d="M280.78,412.383a.187.187,0,0,0,0-.26.2.2,0,0,0-.268,0,.249.249,0,0,0-.059.129.179.179,0,0,0,.05.129.164.164,0,0,0,.129.059.169.169,0,0,0,.148-.056"
                                                transform="translate(-277.733 -409.36)"
                                            ></path>
                                            <path
                                                class="cls-webwallet-2"
                                                d="M289,420.395a.19.19,0,1,0-.268,0,.168.168,0,0,0,.129.05.237.237,0,0,0,.14-.05"
                                                transform="translate(-285.428 -416.844)"
                                            ></path>
                                            <path
                                                class="cls-webwallet-2"
                                                d="M296.756,428.568a.164.164,0,0,0,.059-.129.161.161,0,0,0-.059-.129.191.191,0,0,0-.28,0,.165.165,0,0,0-.059.129.161.161,0,0,0,.059.129.213.213,0,0,0,.28,0"
                                                transform="translate(-292.661 -424.489)"
                                            ></path>
                                            <path
                                                class="cls-webwallet-2"
                                                d="M305.191,436.564a.157.157,0,0,0-.059-.129.187.187,0,0,0-.26,0h0a.2.2,0,0,0,0,.268h0a.2.2,0,0,0,.268,0A.267.267,0,0,0,305.191,436.564Z"
                                                transform="translate(-300.517 -432.093)"
                                            ></path>
                                            <path
                                                class="cls-webwallet-2"
                                                d="M313.145,444.665a.19.19,0,1,0-.268,0h0a.2.2,0,0,0,.268,0"
                                                transform="translate(-308.001 -439.537)"
                                            ></path>
                                            <path
                                                class="cls-webwallet-2"
                                                d="M320.9,452.357a.164.164,0,0,0,.059-.129.16.16,0,0,0-.059-.129.213.213,0,0,0-.28,0,.164.164,0,0,0-.059.129.161.161,0,0,0,.059.129.2.2,0,0,0,.14.059.186.186,0,0,0,.14-.059"
                                                transform="translate(-315.233 -446.74)"
                                            ></path>
                                            <path
                                                class="cls-webwallet-2"
                                                d="M329.191,460.533a.189.189,0,0,0,.05-.129.2.2,0,0,0-.05-.129.2.2,0,0,0-.268,0,.177.177,0,0,0-.059.129.186.186,0,0,0,.19.179.2.2,0,0,0,.137-.05"
                                                transform="translate(-322.999 -454.384)"
                                            ></path>
                                            <path
                                                class="cls-webwallet-2"
                                                d="M337.458,468.5a.19.19,0,0,0-.268-.268h0a.2.2,0,0,0,0,.268h0a.168.168,0,0,0,.129.05.245.245,0,0,0,.14-.05"
                                                transform="translate(-330.734 -461.828)"
                                            ></path>
                                            <path
                                                class="cls-webwallet-2"
                                                d="M345.139,476.67a.178.178,0,0,0,.059-.129.157.157,0,0,0-.059-.129.187.187,0,0,0-.26,0,.206.206,0,0,0-.05.129.18.18,0,0,0,.05.129.168.168,0,0,0,.129.05.16.16,0,0,0,.131-.05"
                                                transform="translate(-337.927 -469.473)"
                                            ></path>
                                            <path
                                                class="cls-webwallet-2"
                                                d="M353.546,484.642a.183.183,0,0,0,.059-.14.157.157,0,0,0-.059-.129.187.187,0,0,0-.26,0,.143.143,0,0,0-.05.129.186.186,0,0,0,.31.14"
                                                transform="translate(-345.785 -476.917)"
                                            ></path>
                                            <path
                                                class="cls-webwallet-2"
                                                d="M361.289,492.605a.2.2,0,0,0,.011-.26l-.011-.011a.213.213,0,0,0-.28,0,.182.182,0,0,0-.02.249l.02.02a.193.193,0,0,0,.28,0"
                                                transform="translate(-353 -484.361)"
                                            ></path>
                                            <path
                                                class="cls-webwallet-2 reverse"
                                                d="M272.158,372.474a3.251,3.251,0,0,1,3.514,0l1.129-1.118a4.65,4.65,0,0,0-5.773,0Z"
                                                transform="translate(-268.921 -370.351)"
                                            ></path>
                                            <path
                                                class="cls-webwallet-2 reverse"
                                                d="M240.691,407.354a3.165,3.165,0,0,1,0-3.514l-1.118-1.118a4.676,4.676,0,0,0,0,5.753Z"
                                                transform="translate(-238.583 -400.619)"
                                            ></path>
                                            <path
                                                class="cls-webwallet-2 reverse"
                                                d="M275.684,491.281a3.147,3.147,0,0,1-3.536.008l-1.118,1.118a4.545,4.545,0,0,0,2.9,1,4.619,4.619,0,0,0,2.865-1.009Z"
                                                transform="translate(-268.921 -483.425)"
                                            ></path>
                                            <path
                                                class="cls-webwallet-2 reverse"
                                                d="M359.588,403.8a3.057,3.057,0,0,1,.528,1.786,3.221,3.221,0,0,1-.528,1.728l1.129,1.118a4.639,4.639,0,0,0,1.009-2.857,4.545,4.545,0,0,0-1-2.9Z"
                                                transform="translate(-351.727 -400.579)"
                                            ></path>
                                            <path
                                                class="cls-webwallet-2 reverse"
                                                d="M366.229,496.488a.788.788,0,1,1-.788.788h0a.8.8,0,0,1,.788-.788h0"
                                                transform="translate(-357.2 -488.293)"
                                            ></path>
                                            <path
                                                class="cls-webwallet-2 reverse"
                                                d="M242.737,496.488a.789.789,0,1,1-.758.758.8.8,0,0,1,.758-.758"
                                                transform="translate(-241.758 -488.293)"
                                            ></path>
                                            <path
                                                class="cls-webwallet-2 reverse"
                                                d="M366.651,372.729a.789.789,0,0,1,.78.788.78.78,0,0,1-1.56,0,.792.792,0,0,1,.78-.791"
                                                transform="translate(-357.602 -372.574)"
                                            ></path>
                                            <path
                                                class="cls-webwallet-2 reverse"
                                                d="M242.168,372.729a.783.783,0,0,1,.788.769h0a.789.789,0,0,1-.788.78h0a.774.774,0,1,1,0-1.549"
                                                transform="translate(-241.198 -372.574)"
                                            ></path>
                                        </g>
                                    </g></svg
                                >Elrond Web Wallet
                            </div>
                        </div>
                        <svg
                            aria-hidden="true"
                            focusable="false"
                            data-prefix="fas"
                            data-icon="arrow-right"
                            class="svg-inline--fa fa-arrow-right fa-w-14 arrow"
                            role="img"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 448 512"
                        >
                            <path
                                fill="currentColor"
                                d="M190.5 66.9l22.2-22.2c9.4-9.4 24.6-9.4 33.9 0L441 239c9.4 9.4 9.4 24.6 0 33.9L246.6 467.3c-9.4 9.4-24.6 9.4-33.9 0l-22.2-22.2c-9.5-9.5-9.3-25 .4-34.3L311.4 296H24c-13.3 0-24-10.7-24-24v-32c0-13.3 10.7-24 24-24h287.4L190.9 101.2c-9.8-9.3-10-24.8-.4-34.3z"
                            ></path>
                        </svg>
                    </div>
                </div>
            </span>
        </div>
    </div>
</template>

<script>
import { walletConnect } from "@/_helpers";
import { mapGetters } from "vuex";
import QrcodeVue from "qrcode.vue";
import { router } from "../../_helpers";

export default {
    name: "MaiarConnect",
    params: {
        callbackRoute: "/dashboard",
        token: false,
        webWalletButtonLabel: "Connect to Maiar",
    },
    data() {
        return {
            // address: "",
            logedin: false,
            qrSvg: "",
            wcUri: "",
        };
    },
    components: {
        // Login,
        QrcodeVue,
    },
    computed: {
        // loggingIn() {
        //   return localStorage.getItem("loggedIn");
        // },
        ...mapGetters("erdWallet", ["loginMethod", "isWalletConnect", "address"]),
        // ...mapMutations("erdWallet", ["setLoginMethod"]),
    },
    methods: {
        async WalletLogin() {
            this.$store.dispatch("erdWallet/setLoginMethod", "walletconnect");
            this.wcUri = await walletConnect.WalletConnect();
        },
        WebWalletLogin() {
            this.$store.dispatch("erdWallet/setLoginMethod", "webwalletlogin");
            walletConnect.useWebWalletLogin({
                callbackRoute: window.location.pathname,
                token: this.token,
                walletAddress: "https://testnet-wallet.elrond.com/dapp/init",
            });
            this.login();
        },
        WalletLogout() {
            console.log("logout acction");

            return walletConnect.useLogout({
                callbackRoute: "/login",
                token: this.token,
                walletAddress: "https://testnet-wallet.elrond.com/dapp/init",
            });
        },
        async login() {
            this.submitted = true;
            // const { username, password } = this;
            const username = "frontend";
            const password = "test";
            const { dispatch } = this.$store;
            if (username && password) {
                dispatch("login/login", { username, password })
                    .then((user) => {
                        dispatch("user/getUserData", user, { root: true });
                    })
                    .then(() => {
                        router.push("/marketplace");
                    });
            }
        },
    },
    mounted: function () {
        window.setInterval(async () => {
            this.wcUri = await walletConnect.WalletConnect();
        }, 150000);
    },
};
</script>

<style scoped>
@import "../../assets/css/dark.css";

.disconnect-wrapper {
    text-align: center;
    padding-top: 30px;
}

button.connect-maiar-header.connect-btn {
    position: relative;
    margin: auto;
}

.connect-wrapper {
    padding-top: 30px;
    margin: auto;
    right: auto;
    display: flex;
    justify-content: center;
    align-items: stretch;
    flex-wrap: nowrap;
    flex-direction: row;
}

.btn.connect-btn {
    position: relative;
    padding: 10px 15px;
}

.walletconnect {
    color: #fff;
    text-align: center;
    padding: 30px;
}

.walletconnect h1 {
    font-size: 22px;
    padding-top: 15px;
}

.maiar-login-qr {
    margin: auto;
    height: 220px;
    width: 220px;
}

.maiar-login-qr > div {
    padding: 10px 10px 0px 10px;
    background-color: #fff;
}

.maiar-wc {
    max-width: calc(100% - 10px);
    display: inline-block;
    word-wrap: break-word;
    background-color: #fff;
    color: #000;
    padding: 5px;
    margin-top: 0px;
}
</style>

<style>
.card-container.wrapper-connect-card {
    width: auto;
}

.card-container .connect-card {
    height: auto;
    padding: 25px;
    padding-bottom: 30px;
    position: relative;
    margin-bottom: 30px;
    width: 500px;
}

div.page-container {
    height: auto;
    min-height: 100vh;
}

.unlock-page .btn-unlock .ledger svg.body path {
    fill: #5e6163;
}

.cls-maiar-extension-1 {
    fill: #5e6163;
}

.cls-maiar-extension-2 {
    fill: #fff;
}

.cls-maiar-app-1 {
    fill: #1f43f7;
}

.cls-maiar-app-2 {
    fill: #fff;
}

.cls-webwallet-1 {
    fill: #1f2021;
}

.cls-webwallet-2 {
    fill: #fff;
    fill-rule: evenodd;
}
.title span {
    display: inline-block;
    color: red;
}

.unlock-page .disable .btn-unlock:hover {
    border-color: #49494d;
}

.unlock-page .disable .btn-unlock {
    background-color: #2b2d2e;
}
</style>
