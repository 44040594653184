import Register from '@/pages/register/Register.vue'
import Introduction from '@/pages/introduction/Introduction.vue'
import Mapz from '@/pages/map/Map.vue'
import Collectibles from '@/pages/collectibles/Collectibles.vue'
import Rank from '@/pages/rank/Rank.vue'
import Marketplace from '@/pages/marketplace/Marketplace.vue'

export const routes = [
    {
        name: 'Home',
        path: '/',
        component: Introduction
    },
    {
        name: 'Login',
        path: '/login',
        component: Register
    },
    {
        name: 'Rank',
        path: '/rank',
        component: Rank
    },
    {
        name: 'Map',
        path: '/map',
        component: Mapz
    },
    {
        name: 'Collectibles',
        path: '/collectibles',
        component: Collectibles
    },
    {
        name: 'Marketplace',
        path: '/marketplace',
        component: Marketplace
    },
    {
        name: 'Subcategory',
        path: '/marketplace/:subcategory',
        component: Marketplace
    },

    // otherwise redirect to home
    { 
        path: '*', 
        redirect: '/' 
    }
];