import axios from 'axios';
import { authHeader } from '../../_helpers/auth-header';
import { router } from "@/_helpers";
// import { walletConnect } from "../../_helpers";
import { smartContract } from "@/_helpers";
import countriesIds from '@/json/countriesIds.json';
import { store } from '@/store/store';

const state = {
    cards: [],
    availableCards: [],
    goldCards: [],
    silverCards: [],
    bronzeCards: [],
    palladiumCards: [],
    platinumCards: [],
    countryCards: [],
    // userCards: []
    marketplaceCards: [],
    filterCards: []
}

const getters = {
    cards: state => {
        // console.log(state.cards);
        return state.cards;
    },
    filterCards: state => {
        return state.filterCards;
    },
    countryCards: state => {
        // console.log(state.cards);
        return state.countryCards;
    },
    availableCards: state => {
        return state.availableCards;
    },
    goldCards: state => {
        return state.goldCards;
    },
    bronzeCards: state => {
        return state.silverCards;
    },
    palladiumCards: state => {
        return state.silverCards;
    },
    platinumCards: state => {
        return state.silverCards;
    },
    silverCards: state => {
        return state.silverCards;
    },
    marketplaceCards: state => {
        return state.marketplaceCards;
    },
}

const actions = {
    async getCardbyId({state}, cardId) {
        // getCards();

        return state.cards.filter(item => {
            return item.id == cardId;
        })
    },
    async getCards({ commit }) {
        await axios
            .get('https://178.18.251.150/api/cards/list', {
                headers: authHeader()
            })
            .then(response => response.data)
            .then(object => {
                commit('setCards', object.success);
            })
            .then(() => {
                commit('setAvailable');
            })
            .catch(error => console.log(error))
    },

    async getGoldCards({ commit }) {
        commit('setGoldCards');
    },
    async getAvailableCards({ commit }) {
        commit('setAvailable');
    },
    async getSilverCards({ commit }) {
        commit('setSilverCards');
    },
    async getBronzeCards({ commit }) {
        commit('setBronzeCards');
    },
    async getPalladiumCards({ commit }) {
        commit('setPalladiumCards');
    },
    async getPlatinumCards({ commit }) {
        commit('setPlatinumCards');
    },
    filter({ commit }, value) {
        if(value.show !== undefined){
            const par = value.show.label.charAt(0).toLowerCase() + value.show.label.slice(1);
            if(par == "all NFTs"){
                router.push("/marketplace/");
                commit('setAvailable');
            } else if(value.show.label && window.location.pathname != "/marketplace/" + par) {
                router.push("/marketplace/" + par);
                commit('set'+ value.show.label + "Cards");
            } 
        } 
        
        const sortDate = state.marketplaceCards.sort(function (a, b) {
            const dateA = a[value.sort.property];
            const dateB = b[value.sort.property];
            
            var price = 0.001
            var activeTrans = a["activeTransactions"]
            if(Object.keys(activeTrans).length > 0){
              let transactions = activeTrans[Object.keys(activeTrans)[0]]
              price = transactions.nextPrice
            }
            const priceA = price

            price = 0.001
            activeTrans = b["activeTransactions"]
            if(Object.keys(activeTrans).length > 0){
              let transactions = activeTrans[Object.keys(activeTrans)[0]]
              price = transactions.nextPrice
            }
            const priceB = price

            if (value.sort.sort == 'desc' && value.sort.property == 'activatedAt') {
                return dateB - dateA;
            } else if (value.sort.sort == 'asc'  && value.sort.property == 'activatedAt') {
                return dateA - dateB;
            } else if(value.sort.sort == 'desc'  && value.sort.property == 'price') {
                return priceB - priceA;
            } else if(value.sort.sort == 'asc' && value.sort.property == 'price') {
                return priceA - priceB;
            } else {
                return true;
            }

        })
        commit('sort', sortDate);
    },
    getCountryCards({ commit }, country_id) {

        let country_obj = countriesIds.find(({ intCode }) => intCode === country_id);
        const result_country = typeof country_obj !== 'undefined' ? country_obj.id : 181
        axios
            .get('https://178.18.251.150/api/card?card_id=' + result_country, {
                headers: authHeader()
            })
            .then(response => response.data)
            .then(object => {
                commit('setCountryCards', object.success);
            })
            .catch(error => console.log(error))
    },

    buyCard({ commit, dispatch }, payload) {
        const user_id = payload['user_id']
        const card_id = payload['card_id']
        var data = `{"user_id": ` + user_id + `,    "card_id": ` + card_id + `}`;
        
        var config = {
            method: 'post',
            url: 'https://178.18.251.150/api/transaction/buy',
            headers: authHeader(),
            data: data
        };
        
        // smartContract.SellCard();
        console.log('buy');
        
        axios(config)
        .then(function (response) {
            console.log(response.data);
            commit('setBuyCardAllert', response.data);
            store.dispatch("user/getUserCards")
            dispatch("getCards");
            dispatch("getCountryCards", response.data.success[0].intCode);
            
        })
        .catch(function (error) {
            console.log(error);
        });
    },
    NEWbuyCard() {
        console.log('buy');
        smartContract.BuyCard();
    },
    sellCard() {
        console.log('sell');
        // smartContract.SellCard();
    }

}

const mutations = {
    setCards(state, object) {
        state.cards = object;
    },
    setAvailable(state) {
        state.marketplaceCards = state.cards.filter(item => {
            return item.status == 'AVAILABLE';
        });
    },
    sort(state, object) {
        state.marketplaceCards = object;
    },
    setGoldCards(state) {
        state.marketplaceCards = state.cards.filter(item => {
            return item.cardType.name == "country_gold" && item.status == 'AVAILABLE';
        })
    },
    setSilverCards(state) {
        state.marketplaceCards = state.cards.filter(item => {
            return item.cardType.name == "country_silver" && item.status == 'AVAILABLE';
        })
    },
    setBronzeCards(state) {
        state.marketplaceCards = state.cards.filter(item => {
            return item.cardType.name == "country_bronze" && item.status == 'AVAILABLE';
        })
    },
    setPalladiumCards(state) {
        state.marketplaceCards = state.cards.filter(item => {
            return item.cardType.name == "country_palladium" && item.status == 'AVAILABLE';
        })
    },
    setPlatinumCards(state) {
        state.marketplaceCards = state.cards.filter(item => {
            return item.cardType.name == "country_platinum" && item.status == 'AVAILABLE';
        })
    },
    setCountryCards(state, object) {
        state.countryCards = object;
    },
    setBuyCardAllert(object) {
        console.log(object);
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};