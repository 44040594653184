const state = {
    read: true,
    guideOne: true,
    guideTwo: false
}

const getters = {
    read: state => {
        return state.read;
    }
}
const actions = {

}

const mutations = {
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};