<template>
  <div class="cards-wrp">
    <div class="card-container grey drop-shadow wrapper-connect-card">
      <div class="connect-card">
        <div class="logo-wrapper">
          <img src="@/assets/images/dark-logo.png" alt="dark-logo" />
        </div>
        <p class="wallet-title">Connect your Elrond Wallet to continue</p>
        <form class="connect-form" action="">
          <input
            type="submit"
            v-model="name"
            class="first-step-btn"
            @click.prevent="connectTo()"
            :class="{ active: connecting }"
            id="connect-maiar"
          />
          <label for="connect-maiar" class="connect-maiar-label"></label>
          <div class="checkbox-wrapper">
            <div class="first-checkbox">
              <div class="round-one">
                <input
                  type="checkbox"
                  class="connect-checkbox"
                  id="checkbox"
                  required
                />
                <label for="checkbox" class="first"></label>
              </div>
              <p class="checkbox-txt">
                I have read the Guide and I know the implications in using the
                app.
              </p>
            </div>
            <div class="second-checkbox">
              <div class="round-two">
                  <input
                    type="checkbox"
                    class="connect-checkbox"
                    id="checkbox-two"
                  />
                <label for="checkbox-two" class="second"></label>
              </div>
              <p class="checkbox-txt long-txt">
                *To play ‘eEarth’ is necessary to connect to your personal
                Elrond Wallet. If you don’t have one, create one in seconds
                here.
              </p>
            </div>
            <div>
              <div class="round-three">
                <input
                  type="checkbox"
                  class="connect-checkbox"
                  id="checkbox-three"
                  required
                />
                <label for="checkbox-three"></label>
              </div>
              <p class="checkbox-txt">
                *eEarth team does not have acces to your wallet keys
              </p>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "Connect",
  data() {
    return {
      name: "Connect Maiar",
    };
  },
  computed: {
    ...mapGetters("connect", ["connecting"]),
  },
  methods: {
    // ...mapActions('connect', ['connectTo']),


  },
};
</script>

<style>
@import "../../assets/css/connect.css";
</style>