import Vue from 'vue';
import Vuex from 'vuex';
import App from './App.vue';
import '@fortawesome/fontawesome-free/css/all.css';
import '@fortawesome/fontawesome-free/js/all.js';
import { store } from './store/store';
import { router } from '../src/_helpers';
import VueLayers from 'vuelayers';
import vSelect from 'vue-select'
// import Carousel3d from 'vue-carousel-3d';
import VueGlide from 'vue-glide-js'
import 'vue-glide-js/dist/vue-glide.css'

import axios from 'axios';
import VueAxios from 'vue-axios';

Vue.config.productionTip = false

Vue.use(VueLayers);
Vue.use(Vuex);
Vue.use(VueAxios, axios);
// Vue.use(Carousel3d);
Vue.use(VueGlide)
Vue.component('v-select', vSelect)

// setup fake backend
import { configureFakeBackend } from './_helpers';
configureFakeBackend();

new Vue({
    render: h => h(App),
    el: '#app',
    router,
    store,
}).$mount('#app');
