<template>
    <div ref="map-root" style="width: 100%; height: 100%">
        <div class="info-tooltip" v-if="this.tooltip.show == true" :style="style">
            {{ this.tooltip.name }}
        </div>
    </div>
</template>

<script>
import View from "ol/View";
import Map from "ol/Map";
import VectorLayer from "ol/layer/Vector";
import VectorSource from "ol/source/Vector";
import GeoJSON from "ol/format/GeoJSON";
import { Fill, Stroke, Style, Text } from "ol/style";
import { mapMutations, mapActions } from "vuex";
import "ol/ol.css";
import colors from "@/json/colors.json";

var style = new Style({
    fill: new Fill({
        color: "#25282c",
    }),
    stroke: new Stroke({
        color: "#212329",
        width: 2,
    }),
    text: new Text({
        // font: '12px Calibri,sans-serif',
        // fill: new Fill({
        //   color: '#000',
        // }),
        // stroke: new Stroke({
        //   color: '#319FD3',
        //   width: 0,
        // }),
    }),
});

var highlightStyle = new Style({
    stroke: new Stroke({
        color: "#212329",
        width: 2,
    }),
    fill: new Fill({
        color: "#00d3ea",
    }),
    text: new Text({
        font: "14px Calibri,sans-serif",
        fill: new Fill({
            color: "#000",
        }),
        stroke: new Stroke({
            //   color: '#f00',
            width: 1,
        }),
    }),
});

export default {
    name: "MapContainer",
    components: {},
    props: {
        geojson: Object,
        // availableCards: null,
    },
    data: () => ({
        olMap: null,
        vectorLayer: null,
        selectedFeature: null,
        tooltip: {
            top: "100px",
            left: "100px",
            name: "Info",
            show: false,
        },
    }),
    computed: {
        style() {
            return "top: " + this.tooltip.top + "; left: " + this.tooltip.left;
        },
    },

    beforeCreate() {},
    created() {
        // this.getCards;
    },
    mounted() {
        var available = this.$store.getters["cards/marketplaceCards"];
        var thisvar = this;
        this.vectorLayer = new VectorLayer({
            source: new VectorSource({
                features: [],
            }),
            style: function (feature) {
                style.getText().setText(feature.get("name"));
                style.setFill(
                    new Fill({
                        color: thisvar.countryColor(feature.getId()),
                    })
                );
                return style;
            },
        });
        this.olMap = new Map({
            target: this.$refs["map-root"],
            layers: [this.vectorLayer],
            view: new View({
                zoom: 4,
                center: [45, 0],
                constrainResolution: true,
            }),
        });
        this.olMap.on("click", (event) => {
            const hovered = this.olMap.forEachFeatureAtPixel(
                event.pixel,
                (feature) => feature
            );
            if (typeof hovered !== "undefined") {
                let select_available = available.find(
                    ({ intCode }) => intCode === hovered.getId()
                );

                if (typeof select_available !== "undefined") {
                    if (hovered !== this.selectedFeature) {
                        console.log(hovered);
                        this.$set(this, "selectedFeature", hovered);
                    }
                    if (typeof hovered !== "undefined") {
                        this.showCards();
                    }
                } else {
                    this.hideCards();
                }
            } else {
                this.hideCards();
            }
        });

        var featureOverlay = new VectorLayer({
            source: new VectorSource(),
            map: this.olMap,
            style: function (feature) {
                highlightStyle.getText().setText(feature.get("name"));
                return highlightStyle;
            },
        });

        var highlight;
        // var highlight_available = false;
        var displayFeatureInfo = (evt) => {
            var pixel = evt.pixel;
            this.tooltip.left = pixel[0] + 15 + "px";
            this.tooltip.top = pixel[1] - 35 + "px";

            var feature = this.olMap.forEachFeatureAtPixel(pixel, function (feature) {
                return feature;
            });

            if (feature !== highlight) {
                if (typeof feature !== "undefined") {
                    this.tooltip.name = feature.get("name");
                    const featureId = feature.getId();
                    let select_available = available.find(
                        ({ intCode }) => intCode === featureId
                    );
                    if (typeof select_available !== "undefined") {
                        if (feature) {
                            featureOverlay.getSource().addFeature(feature);
                            this.tooltip.show = true;
                        }
                    } else {
                        this.tooltip.show = false;
                    }
                } else {
                    this.tooltip.show = false;
                }
                if (highlight) {
                    if (featureOverlay.getSource().hasFeature(highlight)) {
                        featureOverlay.getSource().removeFeature(highlight);
                    }
                }
                highlight = feature;
            }
        };

        this.olMap.on("pointermove", function (evt) {
            if (evt.dragging) {
                return;
            }

            displayFeatureInfo(evt);
        });

        this.updateSource(this.geojson);
    },
    watch: {
        //    this.$store.getters["cards/marketplaceCards"]: function() {
        "$store.state.cards.marketplaceCards": function () {
            this.updateSource(this.geojson);
        },
        geojson(value) {
            this.updateSource(value);
        },
        selectedFeature(value) {
            this.tooltip.show = false;
            this.$emit("select", value);
            if (typeof value !== "undefined") {
                console.log(value.getId());
                this.getCountryCards(value.getId());
            }
        },
    },
    methods: {
        updateSource(geojson) {
            const view = this.olMap.getView();
            const source = this.vectorLayer.getSource();
            const features = new GeoJSON({
                featureProjection: "EPSG:3857",
            }).readFeatures(geojson);
            source.clear();
            source.addFeatures(features);
            view.fit(source.getExtent());
            view.setCenter([7.85, 47.983333]);
        },
        updateVectorLayer() {
            var thisvar = this;
            this.vectorLayer = new VectorLayer({
                source: new VectorSource({
                    features: [],
                }),
                style: function (feature) {
                    style.getText().setText(feature.get("name"));
                    style.setFill(
                        new Fill({
                            color: thisvar.countryColor(feature.getId()),
                        })
                    );
                    return style;
                },
            });
        },

        ...mapMutations("mapCards", ["showCards", "hideCards"]),
        ...mapActions("cards", ["getCountryCards"]),

        countryColor(featureId) {
            let available = this.$store.getters["cards/marketplaceCards"];
            let card = available.find(({ intCode }) => intCode === featureId);
            if (
                card !== undefined &&
                Object.prototype.hasOwnProperty.call(card, "activeTransactions")
            ) {
                var activeTrans = card.activeTransactions;

                if (Object.keys(activeTrans)[0] !== undefined) {
                    const transactions = activeTrans[Object.keys(activeTrans)[0]];
                    const sel_color = colors.find(
                        ({ id }) => id === transactions.user.id
                    );
                    return sel_color.color + "";
                }
                return "#1c1e21";
            }
            return "#25282c";
        },
    },
};
</script>
<style scoped>
.info-tooltip {
    position: absolute;
    z-index: 9999;
    color: #fff;
    padding: 5px 10px;
    background: linear-gradient(130.46deg, #26272b 12.58%, #34393f 93.19%);
    box-shadow: -5px -5px 20px #40464e, 4px 3px 19px #0d0e0f;
    border-radius: 10px;
}
</style>
