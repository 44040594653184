var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.windowWidth > 768)?_c('div',{staticClass:"header"},[_c('router-link',{attrs:{"to":"/"}},[_c('div',{staticClass:"logo"},[_c('img',{attrs:{"src":require("@/assets/images/Eniversum_Logo.png"),"alt":"eEarth"}})])]),_c('nav',{attrs:{"id":"navbar"}},[_c('ul',{staticClass:"main-menu"},[_c('router-link',{attrs:{"to":"/"}},[_vm._v(" Home ")]),_c('router-link',{attrs:{"to":"/collectibles"}},[_vm._v(" MyCollectibles")]),_c('router-link',{attrs:{"to":"/map"}},[_vm._v(" Map")]),_c('router-link',{attrs:{"to":"/rank"}},[_vm._v(" Rank")]),_c('router-link',{staticClass:"arrow-down",class:{
                    'router-link-active': _vm.urlPath == '/marketplace/palladium',
                    'router-link-active': _vm.urlPath == '/marketplace/platinium',
                    'router-link-active': _vm.urlPath == '/marketplace/gold',
                    'router-link-active': _vm.urlPath == '/marketplace/silver',
                    'router-link-active': _vm.urlPath == '/marketplace/bronze',
                },attrs:{"to":"/marketplace"}},[_vm._v(" Marketplace "),_c('ul',{staticClass:"droptown-content"},[_c('span',{on:{"click":function($event){return _vm.getAvailableCards()}}},[_c('router-link',{attrs:{"to":"/marketplace"}},[_vm._v("ALL NFTs")])],1),_c('span',{on:{"click":function($event){return _vm.getPalladiumCards()}}},[_c('router-link',{staticClass:"palladium",attrs:{"to":{
                                name: 'Subcategory',
                                params: { subcategory: 'palladium' },
                            }}},[_vm._v(" PALLADIUM")])],1),_c('span',{on:{"click":function($event){return _vm.getPlatinumCards()}}},[_c('router-link',{staticClass:"platinum",attrs:{"to":{
                                name: 'Subcategory',
                                params: { subcategory: 'platinium' },
                            }}},[_vm._v("PLATINIUM")])],1),_c('span',{on:{"click":function($event){return _vm.getGoldCards()}}},[_c('router-link',{staticClass:"gold",attrs:{"to":{
                                name: 'Subcategory',
                                params: { subcategory: 'gold' },
                            }}},[_vm._v(" GOLD")])],1),_c('span',{on:{"click":function($event){return _vm.getSilverCards()}}},[_c('router-link',{staticClass:"silver",attrs:{"to":{
                                name: 'Subcategory',
                                params: { subcategory: 'silver' },
                            }}},[_vm._v(" SILVER")])],1),_c('span',{on:{"click":function($event){return _vm.getBronzeCards()}}},[_c('router-link',{staticClass:"bronze",attrs:{"to":{
                                name: 'Subcategory',
                                params: { subcategory: 'bronze' },
                            }}},[_vm._v(" BRONZE")])],1)])])],1)]),_c('div',{staticClass:"login-header-wrp"},[(_vm.isloggedIn)?_c('button',{staticClass:"disconnect-maiar-header eni-btn",on:{"click":function($event){return _vm.logout()}}},[_vm._v(" "+_vm._s(_vm.herotag)+" ")]):_vm._e(),(!_vm.isWalletConnect)?_c('button',{staticClass:"connect-maiar-header login-header eni-btn",on:{"click":function($event){return _vm.logout()}}},[_vm._v(" Connect ")]):_vm._e()]),_c('div',{staticClass:"connect-wrapper"},[_c('button',{staticClass:"connect-btn",class:{ opened: _vm.isActive },on:{"click":_vm.burger}},[_c('div',{staticClass:"burger-button",class:{ active: _vm.isActive },attrs:{"id":"burger"}},[_c('span',{staticClass:"burger-bar burger-bar--1"}),_c('span',{staticClass:"burger-bar burger-bar--2"}),_c('span',{staticClass:"burger-bar burger-bar--3"})]),_c('transition',{attrs:{"name":"slide"}},[_c('ul',{staticClass:"submenu-right-wrapper",class:{ opened: _vm.isActive }},[_c('router-link',{attrs:{"to":"/"}},[_c('li',{staticClass:"desktop-guide"},[_vm._v("Guide")])]),_c('li',[_vm._v("Change Username")]),_c('li',{staticClass:"desktop-comunities"},[_vm._v("Communities")]),_c('li',[_vm._v("Follow us on Twitter")])],1)])],1)])],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }