<template>
    <div class="header" v-if="windowWidth > 768">
        <router-link to="/">
            <div class="logo">
                <img src="@/assets/images/Eniversum_Logo.png" alt="eEarth" />
            </div>
        </router-link>
        <nav id="navbar">
            <ul class="main-menu">
                <router-link to="/"> Home </router-link>
                <router-link to="/collectibles"> MyCollectibles</router-link>
                <router-link to="/map"> Map</router-link>
                <router-link to="/rank"> Rank</router-link>
                <router-link
                    to="/marketplace"
                    :class="{
                        'router-link-active': urlPath == '/marketplace/palladium',
                        'router-link-active': urlPath == '/marketplace/platinium',
                        'router-link-active': urlPath == '/marketplace/gold',
                        'router-link-active': urlPath == '/marketplace/silver',
                        'router-link-active': urlPath == '/marketplace/bronze',
                    }"
                    class="arrow-down"
                >
                    Marketplace
                    <ul class="droptown-content">
                        <span @click="getAvailableCards()">
                            <router-link to="/marketplace">ALL NFTs</router-link>
                        </span>
                        <span @click="getPalladiumCards()">
                            <router-link
                                class="palladium"
                                :to="{
                                    name: 'Subcategory',
                                    params: { subcategory: 'palladium' },
                                }"
                            >
                                PALLADIUM</router-link
                            ></span
                        >
                        <span @click="getPlatinumCards()">
                            <router-link
                                class="platinum"
                                :to="{
                                    name: 'Subcategory',
                                    params: { subcategory: 'platinium' },
                                }"
                                >PLATINIUM</router-link
                            ></span
                        >
                        <span @click="getGoldCards()">
                            <router-link
                                class="gold"
                                :to="{
                                    name: 'Subcategory',
                                    params: { subcategory: 'gold' },
                                }"
                            >
                                GOLD</router-link
                            ></span
                        >
                        <span @click="getSilverCards()">
                            <router-link
                                class="silver"
                                :to="{
                                    name: 'Subcategory',
                                    params: { subcategory: 'silver' },
                                }"
                            >
                                SILVER</router-link
                            >
                        </span>
                        <span @click="getBronzeCards()">
                            <router-link
                                class="bronze"
                                :to="{
                                    name: 'Subcategory',
                                    params: { subcategory: 'bronze' },
                                }"
                            >
                                BRONZE</router-link
                            ></span
                        >
                    </ul>
                </router-link>
            </ul>
        </nav>
        <div class="login-header-wrp">
            <!-- <button
                class="connect-maiar-header eni-btn"
                v-if="!isWalletConnect"
                @click="showConnectPopup(true)"
            >
                Connect to Maiar
            </button> -->
            <button
                class="disconnect-maiar-header eni-btn"
                @click="logout()"
                v-if="isloggedIn"
            >
                {{ herotag }}
            </button>
            <button
                class="connect-maiar-header login-header eni-btn"
                v-if="!isWalletConnect"
                @click="logout()"
            >
                Connect
            </button>
        </div>
        <div class="connect-wrapper">
            <button class="connect-btn" @click="burger" :class="{ opened: isActive }">
                <div id="burger" class="burger-button" :class="{ active: isActive }">
                    <span class="burger-bar burger-bar--1"></span>
                    <span class="burger-bar burger-bar--2"></span>
                    <span class="burger-bar burger-bar--3"></span>
                </div>

                <transition name="slide">
                    <ul class="submenu-right-wrapper" :class="{ opened: isActive }">
                        <router-link to="/"
                            ><li class="desktop-guide">Guide</li></router-link
                        >
                        <li>Change Username</li>
                        <li class="desktop-comunities">Communities</li>
                        <li>Follow us on Twitter</li>
                    </ul>
                </transition>
            </button>
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
    name: "headerSection",
    data() {
        return {
            windowWidth: window.innerWidth,
            isActive: false,
            activeTab: 1,
            urlPath: this.$route.path,
        };
    },
    methods: {
        burger() {
            if (this.isActive == false) {
                return (this.isActive = true);
            } else {
                return (this.isActive = false);
            }
        },
        logout() {
            const { dispatch } = this.$store;
            dispatch("erdWallet/logout");
            dispatch("login/logout");
            location.reload(true);
        },
        ...mapActions("login", ["logout"]),
        ...mapActions("cards", ["getSilverCards"]),
        ...mapActions("cards", ["getGoldCards"]),
        ...mapActions("cards", ["getPalladiumCards"]),
        ...mapActions("cards", ["getPlatinumCards"]),
        ...mapActions("cards", ["getBronzeCards"]),
        ...mapActions("cards", ["getAvailableCards"]),
        ...mapActions("erdWallet", ["showConnectPopup"]),
        // asd() {
        //   this.availableCards.forEach(element => {
        //     console.log(element);
        //   });
        // }
    },
    computed: {
        isloggedIn() {
            return this.$store.getters["login/isloggedIn"];
        },
        userData() {
            return this.$store.getters["user/userData"];
        },
        availableCards() {
            return this.$store.getters["cards/marketplaceCards"];
        },
        silverCards() {
            return this.$store.getters["user/silverCards"];
        },

        ...mapGetters("erdWallet", [
            "loginMethod",
            "isWalletConnect",
            "address",
            "herotag",
        ]),
    },
};
</script>

<style>
@import "../../assets/css/header.css";
</style>
