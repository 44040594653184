<template>
    <div class="cards-wrp signup">
        <div class="card-container grey drop-shadow wrapper-connect-card">
            <div class="connect-card">
                <div class="logo-wrapper">
                    <img src="@/assets/images/Eniversum_Logo.png" alt="dark-logo" />
                </div>
                <form @submit.prevent="login" class="connect-form">
                    <div class="input-text-wrapper">
                        <input
                            type="text"
                            placeholder="Register username"
                            class="name-input"
                            v-model="username"
                        />
                    </div>
                    <div class="checkbox-wrapper">
                        <div class="register-checkout">
                            <div class="round-three">
                                <input
                                    type="checkbox"
                                    class="connect-checkbox"
                                    id="checkbox-three"
                                />
                                <label for="checkbox-three"></label>
                            </div>
                            <p class="checkbox-txt">
                                *Username cannot be changed after this step
                            </p>
                        </div>
                    </div>
                    <div class="input-text-wrapper">
                        <input
                            type="password"
                            placeholder="password"
                            class="name-input"
                            v-model="password"
                        />
                    </div>
                    <div class="input-text-wrapper">
                        <input
                            type="password"
                            placeholder="repassword"
                            class="name-input"
                            v-model="repassword"
                        />
                        <input
                            type="submit"
                            name="repassword"
                            value=""
                            class="register-btn"
                        />
                    </div>
                    <div class="checkbox-wrapper">
                        <div class="register-checkout">
                            <p class="checkbox-txt a-hover" v-on:click="backToLogin">
                                Back to Login
                            </p>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>

<script>
// import axios from 'axios';

import { router } from "../../_helpers";

export default {
    name: "Login",
    computed: {
        loggingIn() {
            return this.$store.state.authentication.status.loggingIn;
        },
    },
    created() {
        // reset login status
        this.$store.dispatch("login/logout");
    },
    components: {},
    methods: {
        backToLogin() {
            this.$emit("clicked", false);
        },
        async login() {
            // console.log('merge butonul');
            this.submitted = true;
            const { username, password } = this;
            const { dispatch } = this.$store;
            if (username && password) {
                dispatch("login/login", { username, password })
                    .then((user) => {
                        dispatch("user/getUserData", user, { root: true });
                    })
                    .then(() => {
                        router.push("/marketplace");
                    });
            }
        },
    },
};
</script>

<style>
@import "../../assets/css/register.css";
.logo-wrapper {
    padding: 30px;
}
.logo-wrapper img {
    max-width: 100%;
}
</style>
