<template>
  <div>
    <div>
      <loading :active="isLoading" />
      <!-- <template v-if="sort == true">
      <card-map
        v-for="(card, index) in sort"
        :key="index"
        :card="card"
        class="gold w-points"
      />
      </template> -->
      <card-map
        v-for="(card, index) in marketplaceCards"
        :key="index"
        :card="card"
        class="gold w-points"
      />
      <p
        v-if="marketplaceCards == 0">Nu exista carduri disponibileeee!</p>
    </div>
  </div>
</template>

<script>
import cardMap from "@/components/carousel/cardMap";
import { mapActions } from "vuex";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";

export default {
  name: "Subcateg",
  components: {
    cardMap,
    Loading,
  },
  data() {
    return {
      urlPath: this.$route.path,
      isLoading: false,
    };
  },
  computed: {
    url() {
      return this.urlPath;
    },
    sort() {
      return this.$store.getters["cards/filterCards"];
    },
    marketplaceCards() {
      return this.$store.getters["cards/marketplaceCards"];
    },
    ...mapActions("cards", ["getCards"]),
    ...mapActions("cards", ["getAvailableCards"]),
    ...mapActions("cards", ["getSilverCards"]),
    ...mapActions("cards", ["getGoldCards"]),
    ...mapActions("cards", ["getBronzeCards"]),
    ...mapActions("cards", ["getPalladiumCards"]),
    ...mapActions("cards", ["getPlatinumCards"]),
    ...mapActions("cards", ["filter"]),
  },

  created() {
    this.getCards;
    var compthis = this;
    if (this.urlPath === "/marketplace/silver") {
      setTimeout(function () {
        compthis.getSilverCards;
      }, 1000);
    } else if (this.urlPath === "/marketplace/gold") {
      setTimeout(function () {
        compthis.getGoldCards;
      }, 1000);
    } else if (this.urlPath === "/marketplace/bronze") {
      setTimeout(function () {
        compthis.getBronzeCards;
      }, 1000);
    } else if (this.urlPath === "/marketplace/palladium") {
      setTimeout(function () {
        compthis.getPlatinumCards;
      }, 1000);
    } else if (this.urlPath === "/marketplace/platinum") {
      setTimeout(function () {
        compthis.getPlatinumCards;
      }, 1000);
    }
    this.isLoading = true;
    // simulate AJAX
    setTimeout(() => {
      this.isLoading = false;
    }, 1000);
  },
};
</script>