<template>
    <div class="left-side">
        <!-- <button @click="getCards">MERGEEE</button> -->
        <carousel
            :autoplay="false"
            :nav="true"
            :dots="false"
            :loop="false"
            :margin="0"
            v-if="isAvailable"
            :responsive="{
                '0': { items: 1 },
                '480': { items: 2 },
                '600': { items: 3 },
                '1000': { items: 5 },
                '1200': { items: 7 },
            }"
        >
            <card-map
                v-for="(card, index) in userCards"
                :key="index"
                :card="card"
                class="gold collectibles-card"
            />
        </carousel>
    </div>
</template>

<script>
import carousel from "vue-owl-carousel";
import cardMap from "./cardMap";
import { mapActions } from "vuex";

export default {
    name: "cardCarousel",
    data() {
        return {
            active: 0,
        };
    },
    components: {
        carousel,
        cardMap,
    },
    computed: {
        userCards() {
            return this.$store.getters["user/userCards"];
        },
        isAvailable() {
            if (this.userCards.length > 0) {
                return true;
            } else {
                return false;
            }
        },
    },
    methods: {
        ...mapActions("user", ["getUserCards"]),
    },
    mounted() {
        this.getUserCards();
    },
};
</script>

<style scoped>
@import "../../assets/css/card-carousel.css";
</style>

<style>
.carousel-3d-slide {
    border-color: unset !important;
    background-color: unset !important;
    opacity: 1 !important;
    visibility: visible !important;
    border-style: unset !important;
    text-align: center !important;
}
</style>
