<template>
  <div class="airdrop-wrapper">
    <h2>What are Ticket Airdrops?</h2>
    <div class="airdop-parag-wrapper">
      <p>
        Each card consist in a number of points that will be assigned to the
        owners.
      </p>
      <p>Owning the following cards will give you these points:</p>
      <div class="airdrop-classes">
        <p><span class="palladium">PALLADIUM</span> / 10 points</p>
        <p><span class="platinum">PLATINUM</span> / 8 points</p>
        <p><span class="gold">GOLD</span> / 6 points</p>
        <p><span class="silver">SILVER</span> / 4 points</p>
        <p><span class="bronze">BRONZE</span> / 2 points</p>
      </div>
      <p>
        Acumulating points will give you a higher rank. Having a higher rank
        will give you more tickets for the random Airdrop. A capital or a
        Landmark.
      </p>
      <p>
        Acumulating points will give you a higher rank. Having a higher rank
        will give you more tickets for the random Airdrop. A capital or a
        Landmark.
      </p>
      <p>
        Acumulating points will give you a higher rank. Having a higher rank
        will give you more tickets for the random Airdrop. A capital or a
        Landmark.
      </p>
      <p>
        Acumulating points will give you a higher rank. Having a higher rank
        will give you more tickets for the random Airdrop. A capital or a
        Landmark.
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "Airdrop",
};
</script>