<template>
    <div>
        <div class="filter-block">
            <div style="width: 100%">
                <div class="popup-close" @click="close">x</div>
                <h2>My collectibles Filter</h2>
                <div>
                    <div class="sort-show-wrapper">
                        <loading :active="isLoading" />
                        <!-- <sort :sortOption="sortOption" /> -->
                        <v-select
      v-show="isOpen"
                            placeholder="Sort By"
                            v-model="selectedSort"
                            :options="sortOption"
                        />
                    </div>
                    <div class="sort-show-wrapper">
                        <!-- <show :showOption="showOption" /> -->
                        <v-select
                            placeholder="Show"
                            v-model="selectedShow"
                            :options="showOption"
                        />
                    </div>
                </div>
                <button class="confirm-filter" @click="sortOnApi()">CONFIRM</button>
            </div>
        </div>
    </div>
</template>

<script>
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import { mapActions } from "vuex";

export default {
    name: "Filters",
    components: {
        // Sort,
        // Show,
        Loading,
    },
    data() {
        return {
            urlPath: this.$route.path,
            isLoading: false,
            availableCards: this.getAvailableCards,
            selectedShow: "all",
            selectedSort: this.Sort,
            showOption: [
                { label: "All NFTs", id: "all" },
                { label: "Palladium", id: "palladium" },
                { label: "Platinum", id: "platinum" },
                { label: "Gold", id: "gold" },
                { label: "Silver", id: "silver" },
                { label: "Bronze", id: "bronze" },
            ],
            sortOption: [
                { label: "Date Asc.", property: "activatedAt", sort: "asc" },
                { label: "Date Desc.", property: "activatedAt", sort: "desc" },
                { label: "Buy Price Asc.", property: "price", sort: "asc" },
                { label: "Buy Price Desc.", property: "price", sort: "desc" },
            ],
        };
    },
    computed: {
        ...mapActions("cards", ["getAvailableCards"]),
    },
    created() {
        var route_param = this.$route.params;
        this.selectedShow = this.showOption.filter(item => {
            return item.id === route_param.subcategory;
        })[0];
        this.openSelect();
    },
    methods: {
        sortOnApi() {
            console.log("this.selectedShow", this.selectedShow);
            console.log("this.selectedSort", this.selectedSort);
            this.$emit('change', this.selectedSort);
            const value = {
                show: this.selectedShow,
                sort: this.selectedSort,
            };
            this.$store.dispatch("cards/filter", value);
            this.close();
        },
    openSelect() {
      if (this.isOpen) {
        return this.$nextTick(() => this.$refs.localeSearch.searchEl.focus());
      }
      this.isOpen = !this.isOpen;
    }
    },
    props: ["close", "Sort"],
};
</script>

<style>
@import "../../assets/css/filter.css";
@import "../../assets/css/responsive.css";
</style>
