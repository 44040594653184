<template>
  <div class="introduction-wrapper">
    <div>
      <img
        class="nasa-img"
        src="@/assets/images/introduction/nasa.png"
        alt="img"
      />
    </div>
    <first-step v-if="guideOne == true" :readGuide="readGuide" />
    <guide v-if="guideTwo == true" :connectTo="connectTo" :closeGuide="closeGuide" />
    <connect v-if="connect == true" />
  </div>
</template>

<script>
import FirstStep from "@/components/introduction/FirstStep.vue";
import Guide from "@/components/introduction/Guide.vue";
import Connect from "@/components/connect/Connect.vue";

export default {
  name: "Introduction",
  components: {
    'first-step': FirstStep,
    Guide,
    Connect,
  },
  data() {
    return {
      guideOne: true,
      guideTwo: false,
      connect: false
    };
  },
  methods: {
      readGuide() {
        this.guideOne = false;
        this.guideTwo = true;
      },
      connectTo() {
        this.guideTwo = false;
        this.connect = true;
      },
      closeGuide() {
        this.guideTwo = false;
        this.guideOne = true;
      }
  },
};
</script>

<style>
.nasa-img {
  width: 100%;
}
</style>