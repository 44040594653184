<template>
  <div class="mapCount-cards popup-cards-wrp" v-if="isCardShow" v-on:click="hideCards">
    <card-map class="card-wrap" v-for="card in cards" :key="card.id" :card="card" />
  </div>
</template>

<script>
import cardMap from "@/components/cards/cardElement.vue";
import Feature from "ol/Feature";
import { mapActions, mapState, mapMutations } from "vuex";

export default {
  name: "mapCardWrapper",
  components: {
    cardMap,
  },
  props: {
    feature: Feature,
  },
  computed: {
    cards() {
      return this.$store.getters["cards/countryCards"];
    },
    availableCards() {
      return this.$store.getters["cards/availableCards"];
    },
    ...mapActions("cards", ["getCountryCards"]),
    ...mapState("mapCards", ["isCardShow"]),
  },
  created() {
    // this.getCountryCards;
  },
  methods: {
    ...mapMutations("mapCards", ["hideCards"]),
  }
};
</script>

<style>
@import "../../assets/css/map.css";
</style>
