<template>
    <div class="page-container">
        <div class="page-bg">
            <video autoplay muted loop id="myVideo">
                <source src="@/assets/Galaxy01.mp4" type="video/mp4" />
            </video>
        </div>

        {{ register }}
        <div v-if="register">
            <Signup @clicked="onClickChild" />
        </div>
        <div v-else>
            <div class="cards-wrp connect-popup">
                <div class="card-container grey drop-shadow wrapper-connect-card">
                    <div class="connect-card">
                        <div class="logo-wrapper">
                            <img
                                src="@/assets/images/Eniversum_Logo.png"
                                alt="dark-logo"
                            />
                        </div>
                        <maiar-connect />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
// import Login from "@/components/connect/Login.vue";
import Signup from "@/components/connect/Signup.vue";
import MaiarConnect from "@/components/connect/MaiarConnect.vue";

export default {
    name: "Register",
    components: {
        // Login,
        Signup,
        MaiarConnect,
    },
    data: () => ({
        register: false,
    }),
    methods: {
        onClickChild(value) {
            this.register = value;
            console.log(value); // someValue
        },
    },
};
</script>

<style scoped>
.page-bg {
    position: fixed;
    top: 0px;
    z-index: 0;
}
div.page-container {
    min-height: calc(100vh - 103px);
}

.cards-wrp {
    /* margin-top: 100px; */
    position: relative;
    z-index: 10;
    top: 350px;
}
</style>
