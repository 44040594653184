import Vue from 'vue';
import VueRouter from 'vue-router';

import { routes } from '../app/routes.js';

Vue.use(VueRouter);

export const router = new VueRouter({
    base: __dirname,
    mode: 'history',
    routes,
    scrollBehavior() {
        return { x: 0, y: 0 }
    }
});

router.beforeEach((to, from, next) => {
    // redirect to login page if not logged in and trying to access a restricted page
    const publicPages = ['/login'];
    const authRequired = !publicPages.includes(to.path);
    const loggedIn = localStorage.getItem('user');

    if (authRequired && !loggedIn) {
        return next('/login');
    }
    // if(to.path == '/') {
    //     return next('/introduction');
    // }

    next();
})