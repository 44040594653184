import axios from 'axios';
import { authHeader, handleResponse} from '../../_helpers/auth-header';
import { globalVars } from './globalVars'

const state = {
    userRank: [],
    userdata: JSON.parse(localStorage.getItem('userdata')),
    userCards: []
}

const api = globalVars.state.api

// state.userdata = JSON.parse(localStorage.getItem('userdata'));

const getters = {
    userRank: state => {
        return state.userRank;
    },
    userData: state => {
        return state.userdata;
    },
    userCards: state => {
        return state.userCards;
    }
}

const actions = {

    async getUserRank({ commit, state }) {

        const user_id = state.userdata.user_id;
        // commit('setUserRank', userRank)
        axios
            .get('https://178.18.251.150/api/user/rank', {
                headers: authHeader(),
                params: {
                    user_id: user_id
                }
            })
            .then(response => response.data)
            .then(object => {
                commit('setUserRank', object.success[0])
            })
            .catch(error => {
                console.log(error)
                handleResponse(error.response);
            })
    },
    

    async getUserData({ commit }) {

        var user_data
        axios
            .get(`${api.url}/api/user/auth`, {
                headers: authHeader(),
            })
            .then(response => response.data)
            .then(object => {
                user_data = object.success
                // console.log(user_data)

                localStorage.setItem('userdata', JSON.stringify(user_data));
                commit('setData', user_data)
                return user_data
            })
            .catch(error => {
                console.log(error)
                handleResponse(error.response);
            })
    },
    getUserCards({ commit }) {
        if(state.userdata){
            const user_id = state.userdata.user_id;
            axios
                .get(`${api.url}/api/user/cards`, {
                    headers: authHeader(),
                    params: {
                        user_id: user_id
                    }
                })
                .then(response => response.data)
                .then(object => {
                    commit('setUserCards', object.success);
                })
                .catch(error => {
                    console.log(error)
                    handleResponse(error.response);
                })
        }
    },
    buyItem() {
        // const user_id = state.userdata.user_id;
        axios
        .post(`${api.url}/api/transaction/buy`, {
            headers: authHeader(),
            params: {
                user_id: 3,
                card_id: 1
            }
        })
        .catch(error => {
            console.log(error)
            handleResponse(error.response);
        })
    },
}

const mutations = {
    setUserRank(state, object) {
        state.userRank = object;
    },
    setData(state, object) {
        state.userdata = object;
    },
    setUserCards(state, object) {
        var availableCards = object.filter(item => {
            return item.card.status == 'AVAILABLE';
        });
        if(availableCards !== undefined){
            var sortCards = availableCards.sort(function (a, b) {
                const priceA = a.nextPrice
                const priceB = b.nextPrice;
                return priceB - priceA;
            });
        } else {
             sortCards = availableCards;
        }
            
            

        state.userCards = sortCards;
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
