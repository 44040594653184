<template>
    <div class="map-country-list">
        <ul class="table-header">
            <li style="width: 10%">No.</li>
            <li style="width: 30%">User</li>
            <li style="width: 30%">Country</li>
            <li style="width: 30%">$EGLD</li>
        </ul>
        <div class="list-wrapper">
            <ul
                class="table-body"
                v-for="(country, index) in countries"
                :key="country.id"
            >
                <li style="width: 10%">{{ index + 1 }}</li>
                <li style="width: 30%" :style="getColor(country)">
                    {{ getUserName(country) }}
                </li>
                <li style="width: 30%">{{ country.name }}</li>
                <li style="width: 30%" class="price-list">{{ getNextPrice(country) }}</li>
            </ul>
        </div>
    </div>
</template>

<script>
import { mapActions } from "vuex";
import colors from "@/json/colors.json";

export default {
    name: "mapCountryList",
    components: {},
    data: () => ({
        count: 0,
    }),
    methods: {
        setDecimal(value) {
            const decimalValue = +value;
            return decimalValue.toFixed(2);
        },
        getColor(card) {
            var user = this.getUser(card);
            if (user !== undefined) {
                var color = colors.find(({ id }) => id == user.id);
                return `color:${color.color}`;
            } else {
                return `color:"#333"`;
            }
        },
        getUser(card) {
            var activeTrans = card.activeTransactions;
            if (Object.keys(activeTrans)[0] !== undefined) {
                const transactions = activeTrans[Object.keys(activeTrans)[0]];
                return transactions.user;
            }
        },
        getNextPrice(card) {
            var activeTrans = card.activeTransactions;
            if (Object.keys(activeTrans)[0] !== undefined) {
                const transactions = activeTrans[Object.keys(activeTrans)[0]];
                return transactions.nextPrice;
            } else {
                return "0,001";
            }
        },
        getUserName(card) {
            var user = this.getUser(card);
            if (user !== undefined) {
                return user.username;
            } else {
                return "not Bouth Yet";
            }
        },
        sortCountries() {
            const value = {
                sort: { label: "Buy Price Asc.", property: "price", sort: "desc" },
            };
            this.$store.dispatch("cards/filter", value);
        },
        increase() {
            this.count = this.count + 1;
            return this.count;
        },
        ...mapActions("cards", ["getAvailableCards", "getCards"]),
    },
    computed: {
        countries() {
            this.sortCountries();
            return this.$store.getters["cards/marketplaceCards"];
        },
    },
    created() {
        this.getCards();
        this.getAvailableCards();
    },
};
</script>

<style>
.map-country-list {
    position: absolute;
    top: 50px;
    right: 50px;
    width: 450px;
    background: linear-gradient(130.46deg, #26272b 12.58%, #34393f 93.19%);
    box-shadow: -5px -5px 20px #40464e, 4px 3px 19px #0d0e0f;
    border-radius: 10px;
}

.map-country-list .list-wrapper {
    max-height: calc(100vh - 100px);
}

.map-country-list .price-list {
    color: #00f17d;
}
</style>
