import { globalVars } from '../store/modules/globalVars'
import { authHeader } from '../_helpers';
import axios from 'axios';
// import user from '../store/modules/user';

export const userService = {
    login,
    logout,
    getAll,
    getUserData,
    getUserName
};

const api = globalVars.state.api

function login(username, password) {
    console.log(api);
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ username, password })
    };

    return fetch(`${api.url}/login_check`, requestOptions)
        // .then(handleResponse)
        .then(response => handleResponse(response, username, password ))
        .then(user => {
            // login successful if there's a jwt token in the response
            if (user.token) {
                // console.log(user.token);
                // store user details and jwt token in local storage to keep user logged in between page refreshes
                localStorage.setItem('user', JSON.stringify(user));
            }

            return user;
        });
}

function signup(username, password) {
    const email = username + "@eniversum.io";
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ username, password, email})
    };

    console.log('requestOptions', requestOptions);

    return fetch(`${api.url}/register`, requestOptions)
        .then(response => handleSignUpResponse(response, username, password ));
}
function logout() {
    // remove user from local storage to log user out
    localStorage.removeItem('user');
    localStorage.removeItem('address');
    localStorage.removeItem('userdata')
    // this.$router.push('/')
}

function getAll() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(`${api.url}/users`, requestOptions).then(handleResponse);
}

async function getUserData() {

    var user_data
    axios
        .get(`http://178.18.251.150/api/user/auth`, {
            headers: authHeader(),
        })
        .then(response => response.data)
        .then(object => {
            user_data = object.success
            localStorage.setItem('userdata', JSON.stringify(user_data));
            return user_data
        })
        .then((user_data) => {
            return  getUserRank( user_data.user_id);
        })
}


async function getUserName() {

    var user = JSON.parse(localStorage.getItem('userdata'));
    if(user == undefined){
        getUserData();
        user = JSON.parse(localStorage.getItem('userdata'));
    }
    return user.username
}

async function getUserRank(user_id) {
    axios
        .get(`http://178.18.251.150/api/user/rank?user_id=` + user_id, {
            headers: authHeader(),
        })
        .then(response => response.data)
        .then(object => {
            console.log(object.success);
            return object.success;
        })
        .catch(error => console.log(error))
}

// .then(response => handleResponse(response, username, password  ))
function handleResponse(response, username, password) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        const isHerotag =  localStorage.getItem('isHerotag');
        console.log('isHerotag', isHerotag);
        console.log('handleRequest', data);
        console.log('handleRequest.response', response);

        if (!response.ok) {
            if (response.status === 400 && isHerotag ==  'true') {
                console.log('incercam aici');
                signup(username, password)
            }
            if (response.status === 401) {
                // auto logout if 401 response returned from api
                logout();
                location.reload(true);
            }

            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }

        return data;
    });
}

function handleSignUpResponse(response, username, password) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        console.log('handleSignUpResponse', data);

        if (!response.ok) {
            if (response.status === 401) {
                location.reload(true);
            }
            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }
        if (response.ok) {
            return login(username, password);
        }
        return data;
    });
}