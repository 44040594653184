<template>
    <div class="card-container">
        <!-- <div class="card-container" 
        v-if="card.status == 'AVAILABLE'"> -->
        <div
            :class="[
                this.getCard.cardType.name == 'country_gold' ? 'gold' : '',
                this.getCard.cardType.name == 'country_silver' ? 'silver' : '',
                this.getCard.cardType.name == 'country_platinum' ? 'platinum' : '',
                this.getCard.cardType.name == 'country_bronze' ? 'bronze' : '',
                this.getCard.cardType.name == 'country_palladium' ? 'palladium' : '',
                this.getMyUsername == this.getUsername ? 'my-card' : '',
            ]"
            class="card country"
            :id="[this.getCard.name.replace(/\s/g, '')]"
        >
            <div id="card-front" class="card-front" v-on:click="showCardElement">
                <div class="card-current-value">
                    <span>Bought for </span><br />
                    <span class="value">{{ this.getPrice }} EGLD</span>
                </div>
            </div>
            <div class="card-back gold">
                <div>
                    <div class="country-name countryname" id="card-name">
                        <p>
                            {{ this.getCard.name }}
                        </p>
                    </div>
                </div>
                <div class="country-population with-label">
                    <span>Population</span><br />
                    <span class="value">211 milion</span>
                </div>
                <div class="country-curency with-label">
                    <span>Currency</span><br />
                    <span class="value">Brasilian real</span>
                </div>
                <div class="country-airdrop with-label">
                    <span>Airdrop points earned per Second</span><br />
                    <span class="value">{{ this.getCard.cardType.rewardPoints }}</span>
                </div>
                <div class="country-owner with-label">
                    <span>NFT Owner</span><br />
                    <!-- <span class="value">asd</span> -->
                    <span class="value">{{ this.getUsername }}</span>
                </div>
                <div class="buy-button">
                    <span>Buy the NFT {{ this.getCard.name }} for</span><br />
                    <button @click="byThisCard()">{{ this.getPrice }} EGLD</button>
                </div>

                <div class="collectibles-back-card">
                    <p>
                        The only way to sell a card is to set a smaller price to attract
                        the buyers.
                    </p>
                    <form action="">
                        <input
                            type="text"
                            value=""
                            class="type-backcard"
                            placeholder="Type..."
                        />
                        <input type="submit" value="CONFIRM" class="confirm-btn" />
                    </form>
                </div>
            </div>
        </div>

        <div class="points-earned">
            <p>Points earned: <span>122</span></p>
        </div>
    </div>
</template>

<script>
import { mapActions, mapMutations } from "vuex";
import { userService } from "@/_services";
// import { mapGetters, mapActions } from "vuex";

export default {
    name: "cardMap",
    data() {
        return {
            // silver: 'silver'
            urlPath: this.$route.path,
            parrentWidth: document.getElementsByClassName("countryname"),
            // userId:
            cartId: null,
        };
    },
    computed: {
        showClass() {
            const status = this.getCard.cardType.name;
            const res = status.split("_");
            const upper = res[1].toUpperCase();
            return upper;
        },
        getUsername() {
            var username = "notBought";

            if (Object.prototype.hasOwnProperty.call(this.card, "card")) {
                username = this.card.user.username;
            } else {
                var activeTrans = this.getCard.activeTransactions;
                if (Object.keys(activeTrans)[0] !== undefined) {
                    const transactions = activeTrans[Object.keys(activeTrans)[0]];
                    username = transactions.user.username;
                }
            }
            return username;
        },
        getMyUsername() {
            return userService.getUserName();
        },
        getCard() {
            // console.log('this.card', this.card);
            if (Object.prototype.hasOwnProperty.call(this.card, "card")) {
                return this.card.card;
            } else {
                return this.card;
            }
        },
        getPrice() {
            var price = 0.001;

            if (Object.prototype.hasOwnProperty.call(this.card, "card")) {
                price = this.card.nextPrice;
            } else {
                var activeTrans = this.getCard.activeTransactions;
                if (Object.keys(activeTrans)[0] !== undefined) {
                    const transactions = activeTrans[Object.keys(activeTrans)[0]];
                    price = transactions.nextPrice;
                }
            }
            return price;
        },
    },
    methods: {
        ...mapActions("cards", ["buyCard", "getCountryCards"]),
        ...mapMutations("mapCards", ["showCards", "hideCards"]),

        byThisCard() {
            const userdata = this.$store.getters["user/userData"];
            const user_id = userdata.user_id;
            this.buyCard({ card_id: this.getCard.id, user_id: user_id });
        },
        showCardElement() {
            this.showCards();
            this.getCountryCards(this.getCard.intCode);
        },
    },
    created() {
        this.cardId = this.getCard.id;
    },
    props: ["side", "card"],
};
</script>

<style>
.flag-img {
    width: 20px;
}

.flag-img-wrapper {
    display: inline-block;
    margin-left: 10px;
}
.slide-name {
    animation: slide 5s infinite;
    -moz-animation: slide 5s infinite;
    -webkit-animation: slide 5s infinite;
    -o-animation: slide 5s infinite;
}

@-webkit-keyframes slide {
    0% {
        -webkit-transform: translate(0px, 0px);
    }
    100% {
        -webkit-transform: translate(-90%, 0px);
    }
}
.w-points .points-earned {
    display: none;
}
.marketplace-page .points-earned {
    display: none;
}
.collectibles-back-card form {
    text-align: center;
}
.collectibles-back-card .type-backcard {
    padding-left: 10px;
    background: linear-gradient(
            147.42deg,
            rgba(64, 72, 93, 0.4) 6.32%,
            rgba(96, 106, 130, 0.4) 92.25%
        ),
        #ffffff;
    background-blend-mode: soft-light, normal;
    border-radius: 40px;
    border: 0px;
    width: 70%;
    height: 25px;
    margin-top: 10px;
}
input.type-backcard:focus {
    outline: none;
}
.collectibles-back-card .confirm-btn {
    cursor: pointer;
    background: linear-gradient(
            147.42deg,
            rgba(64, 72, 93, 0.4) 6.32%,
            rgba(96, 106, 130, 0.4) 92.25%
        ),
        #f50606;
    background-blend-mode: soft-light, normal;
    border-radius: 40px;
    color: #fff;
    border: 0;
    margin: auto;
    width: 70%;
    height: 25px;
    /* margin-top: 30px; */
    bottom: 20px;
    position: absolute;
    left: 0;
    right: 0;
}
.card {
    width: 150px;
    height: 300px;
    margin-top: 115px;
    /* background-color: rgb(45 47 53); */
    font-family: "Josefin Sans", sans-serif;
    text-align: left;
    color: #000;
    position: relative;
    margin: 15px;
    display: inline-block;
    /* box-shadow: 
        0 2.8px 2.2px rgba(0, 0, 0, 0.034),
        0 6.7px 5.3px rgba(0, 0, 0, 0.048),
        0 12.5px 10px rgba(0, 0, 0, 0.06),
        0 22.3px 17.9px rgba(0, 0, 0, 0.072),
        0 41.8px 33.4px rgba(0, 0, 0, 0.086),
        0 100px 80px rgba(0, 0, 0, 0.12); */
    /* box-shadow: -5px 2px 10px rgb(0 0 0 / 55%); */

    box-shadow: -5px -5px 20px #40464e, 4px 3px 19px #0d0e0f;
    border-radius: 14px;
    overflow: hidden;
    z-index: 1;
}

.collectibles-back-card {
    display: none;
}

.card-front:before {
    content: "";
    z-index: 3;
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    /* background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0) 62.29%,
    rgba(0, 0, 0, 0.91) 100%
  ); */
}

.card-front > div {
    z-index: 4;
}

.card .card-front {
    background-repeat: no-repeat;
}

.card .card-front {
    /* background-image: url("~@/assets/images/cards/brazil-32887.jpg"); */
    background-image: url("~@/assets/images/cards/gif/CountryComingSoon.jpg");
    background-size: 100%;
    background-position: center;
}

.card#Brazil .card-front {
    background-image: url("~@/assets/images/cards/gif/Brazil.gif");
}

.card#Switzerland .card-front {
    background-image: url("~@/assets/images/cards/gif/Switzerland.gif");
}

.card#Austria .card-front {
    background-image: url("~@/assets/images/cards/gif/Austria.gif");
}

.card#Cameroon .card-front {
    background-image: url("~@/assets/images/cards/gif/Cameroon.gif");
}

.card#Maldives .card-front {
    background-image: url("~@/assets/images/cards/gif/Maldive.gif");
}

.card#VietNam .card-front {
    background-image: url("~@/assets/images/cards/gif/Vietnam.gif");
}
.card#Romania .card-front {
    background-image: url("~@/assets/images/cards/gif/Cameroon.gif");
}
.card#France .card-front {
    background-image: url("~@/assets/images/cards/gif/EiffelTower.gif");
}
.card#Belgium .card-front {
    background-image: url("~@/assets/images/cards/gif/BELGIUM.gif");
}
.card#Chile .card-front {
    background-image: url("~@/assets/images/cards/gif/Chile.gif");
}
.card#Cyprus .card-front {
    background-image: url("~@/assets/images/cards/gif/Cyprus.gif");
}
.card#Kenya .card-front {
    background-image: url("~@/assets/images/cards/gif/Kenya.gif");
}
.card#Malaysia .card-front {
    background-image: url("~@/assets/images/cards/gif/Malaysia.gif");
}
.card#Malta .card-front {
    background-image: url("~@/assets/images/cards/gif/Malta.gif");
}
.card#Italy .card-front {
    background-image: url("~@/assets/images/cards/gif/Rome.gif");
}
.card#Senegal .card-front {
    background-image: url("~@/assets/images/cards/gif/Senegal.gif");
}

/* country map shape */
.card .card-side:after {
    content: "";
    width: 200px;
    height: 200px;
    position: absolute;
    right: 17px;
    top: 74px;
    background-size: 100%;
    background-repeat: no-repeat;
    background-position: right;
    /* filter: invert(1); */
}
.card .card-side:after {
    background-image: url("~@/assets/images/cards/maps/brasil2.png");
}

.country-flag {
    position: absolute;
    top: 20px;
    /* padding-left: 26px; */
    color: #000;
}

.card-flag {
    width: 20px !important;
    display: inline-block !important;
}

.country-flag p {
    display: inline !important;
    position: relative;
    top: -4px;
    left: 5px;
}

.country-name {
    font-family: "Average", serif;
    top: 44px;
    font-size: 32px;
    color: #000;
    text-align: center;
}

.card-back .country-name {
    color: #fff;
    white-space: nowrap;
    display: inline-block;
    width: 100%;
    overflow: hidden;
}
.owl-carousel .owl-item img {
    display: inline-block !important;
    width: 20px !important;
}
.card-back .country-name p {
    animation: slide-left 10s;
    margin-top: 0;
    margin-bottom: 0;
}
@keyframes slide-left {
    from {
        margin-left: 0%;
        width: 100%;
    }

    to {
        margin-left: -100%;
        width: 100%;
    }
}
.country-continent {
    position: absolute;
    top: 95px;
    font-size: 10px;
    color: #000;
}

.card-current-value {
    position: absolute;
    bottom: 55px;
    color: #fff;
}
.card-current-value span.value {
    font-size: 16px;
}

.card-type {
    position: absolute;
    bottom: 9px;
    color: #fff;
}

.card-type span.value {
    font-family: "Average", serif;
    color: #ffd130;
    font-size: 16px;
}

.card .card-back {
    color: #fff;
    background: linear-gradient(
        167.2deg,
        rgba(52, 57, 62, 0.9) -8.08%,
        rgba(48, 52, 57, 0.9) -8.07%,
        rgba(42, 44, 49, 0.9) 91.64%
    );
}

/* .card .card-back.gold {
  background: linear-gradient(
    167.2deg,
    rgba(238, 168, 31, 0.9) -8.08%,
    rgba(207, 167, 24, 0.9) 91.64%
  );
} */

.gold .card-back {
    background: linear-gradient(
        167.2deg,
        rgba(238, 168, 31, 0.9) -8.08%,
        rgba(207, 167, 24, 0.9) 91.64%
    );
}

.gold .card-type .value {
    color: #ffd130;
}

.platinum .card-type .value {
    color: #00ffe0;
}

.platinum .card-back {
    background: linear-gradient(
        167.2deg,
        rgba(1, 165, 145, 0.9) -8.08%,
        rgba(0, 255, 224, 0.9) 91.64%
    );
}

.bronze .card-type .value {
    color: #cd7f32;
}

.bronze .card-back {
    background: linear-gradient(
        171.79deg,
        rgba(220, 131, 44, 0.9) 4%,
        rgba(211, 131, 51, 0.9) 93.39%
    );
}

.silver .card-type .value {
    color: #a6a6a6;
}

.silver .card-back {
    background: linear-gradient(
        168.79deg,
        rgba(243, 243, 243, 0.9) 3.58%,
        rgba(180, 181, 182, 0.9) 37.87%,
        rgba(186, 186, 187, 0.9) 46.45%,
        rgba(237, 237, 237, 0.9) 96.77%
    );
}
/* .gold .card .card-back {
  background: linear-gradient(
    167.2deg,
    rgba(238, 192, 31, 0.9) -8.08%,
    rgba(48, 52, 57, 0.9) -8.07%,
    rgba(184, 152, 37, 0.9) 91.64%
  );
} */

.buy-button {
    position: absolute;
    bottom: 45px;
    text-align: center;
    width: calc(100% - 20px);
    color: #d9b021;
    font-size: 12px;
}

.gold .buy-button {
    color: #000;
}

.buy-button button {
    margin-top: 10px;
    background: linear-gradient(128deg, #e1ba0f -79.65%, #ffe15d 151.25%);
    /* background-color: #fff; */
    border-radius: 20px;
    padding: 10px 30px;
    font-size: 13px;
    line-height: 100.5%;
    border: none;
    font-family: "Josefin Sans", sans-serif;
    font-weight: bold;
    letter-spacing: -0.5px;
}

.buy-button button:hover {
    /* background-color: #d9b021; */
    background: linear-gradient(128deg, #f54e06 -79.65%, #ce2e18 151.25%);
    color: #fff;
}

/* social buttons */

.card-social {
    position: absolute;
    bottom: 10px;
    text-align: center;
    width: 100%;
    margin-left: -10px;
}

.card-social > div {
    width: 22px;
    height: 22px;
    display: inline-block;
    padding: 0 5px;
    background-repeat: no-repeat;
    background-position: center;
}

.card-social .twitter {
    background-image: url("~@/assets/images/icontwitter.png");
}

.card-social .facebook {
    background-image: url("~@/assets/images/facebook.png");
}

/* card  details */

.card-details {
    position: relative;
    top: 72px;
    left: 7px;
}

.with-label {
    color: #313131;
    line-height: 14px;
    font-size: 10px;
    letter-spacing: -0.4px;
    position: relative;
    display: inline-block;
    width: 100%;
    padding-bottom: 16px;
}

.with-label .value {
    font-size: 16px;
    line-height: 15px;
    color: #e1b500;
}

.card-details .country-curency {
    top: auto;
}

.country-population {
    top: auto;
}

.card-back .country-name {
    top: auto;
}

.country-owner {
    bottom: auto;
}

.country-airdrop {
    bottom: auto;
}

.card-back .country-name:after {
    content: "";
    width: 12px;
    height: 12px;
    background-color: green;
}

.collectibles-card .card-back .country-name:after {
    position: relative;
    right: unset;
    margin: auto;
}
.card-back .country-name:after {
    content: "";
    width: 16px;
    height: 10px;
    background-color: green;
    display: block;
    position: absolute;
    right: -40px;
    top: 16px;
}

.card-container {
    width: 165px;
    display: inline-block;
}

.card {
    position: relative;
    perspective: 1000;
    transform-style: preserve-3d;
    transition: transform 0.8s;
    /* -webkit-backface-visibility: hidden; */
    /* backface-visibility: hidden; */
}
.card.pop-up {
    width: 575px;
    height: 410px;
    background: linear-gradient(
            147.42deg,
            rgba(64, 72, 93, 0.4) 6.32%,
            rgba(96, 106, 130, 0.4) 92.25%
        ),
        linear-gradient(128deg, #e6e7ed -79.65%, #f7f8fa 151.25%);
    background-blend-mode: soft-light, normal;
    border-radius: 20px;
}
.card.pop-up .card-element {
    width: 211px;
    border-radius: 20px;
}

.card.pop-up .card-side {
    left: 211px;
    width: calc(100% - 211px);
}

.card > div {
    position: absolute;
    width: 100%;
    height: 100%;
    padding: 10px;
    box-sizing: border-box;
    transition: display 1s;
    /* -webkit-backface-visibility: hidden; */
    /* backface-visibility: hidden; */
}

.card-container:hover .card.flip {
    transform: rotateY(180deg);
}

.card-container:hover .flip .card-back {
    display: block;
}
.card-container:hover .flip .card-front {
    display: none;
}

.card .card-back {
    transform: rotateY(180deg);
    display: none;
}

.points-earned p {
    color: #00f17d;
    position: relative;
    top: -10px;
}

.points-earned p span {
    color: #00f17d;
    font-weight: bold;
}

@media (max-width: 768px) {
    .card-container {
        width: 185px;
    }
}
</style>
