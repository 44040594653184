import { store } from '../store/store';
import { 
  ProxyProvider, 
  ApiProvider,
  WalletConnectProvider,
  HWProvider,
  ExtensionProvider,
  Address, 
  WalletProvider ,
  } from "@elrondnetwork/erdjs";

var dapp = {
  provider: null,
  proxy: new ProxyProvider("https://gateway.elrond.com", {
    timeout: 10000
  }),
  apiProvider: ApiProvider,
  providerType: ''
};
var walletConnectBridge = "https://bridge.walletconnect.org";
var callbackRoute = "/maiar-conected";
var logoutRoute = "/maiar-disconected";
var heartbeatDisconnectInterval = 15000;

var WalletConnect = async function WalletConnect() {
  var provider = new WalletConnectProvider(dapp.proxy, walletConnectBridge, {
    onClientLogin: handleOnLogin,
    onClientLogout: handleOnLogout
  });
  dapp.provider = provider;
  var walletConnectUri = provider.login();
  return walletConnectUri;
}

var useWebWalletLogin = function useWebWalletLogin(_ref) {
  var callbackRoute = _ref.callbackRoute,
      walletAddress = _ref.walletAddress,
      token = _ref.token;
    var provider = newWalletProvider(walletAddress);
    setItem({
      key: "walletLogin",
      data: true,
      expires: Math.round((+new Date()) / 1000) + 60
    });
    store.dispatch('erdWallet/setProvider', provider);
    // store.erdWallet.setProvider('provider');
    dapp.provider = provider
    provider.login({
      callbackUrl: encodeURIComponent("" + window.location.origin + callbackRoute)
    }, token ? {
      token: token
    } : {});
};


var useLogout = function useLogout(_ref) {
  // var callbackRoute = _ref.callbackRoute,
  var walletAddress = _ref.walletAddress;
      // token = _ref.token;
  var provider = newWalletProvider(walletAddress);
  var providerType = getProviderType(provider);

  // return function (_ref) {
    var callbackUrl = _ref.callbackUrl;
    // storage.session.clear();
    localStorage.removeItem("nonce");
    localStorage.removeItem("address");
    localStorage.removeItem("loginMethod");

    if (providerType) {
      provider.logout({
        callbackUrl: callbackUrl
      }).then(function () {
        dispatch({
          type: "logout"
        });
      })["catch"](function (e) {
        console.error("Unable to perform logout", e);
      });
    } else {
      dispatch({
        type: "logout"
      });
    }
  // };
}

var setItem = function setItem(_ref2) {
  console.log('setItem ->', _ref2);
  localStorage.setItem(_ref2.key, JSON.stringify(_ref2.data));
}

var dispatch = function dispatch(data){

  store.dispatch('erdWallet/' + data.type, );
  console.log('dispatch:', data);
  if(data.type == "login"){
    useGetAccount(data.address).then(function (account) {
      var username = "frontend";
      localStorage.setItem('herotag', username);
      localStorage.setItem('isHerotag', false);
      if(account.userName != ""){
        // username = account.userName.replace(".elrond", "");
        username = account.userName;
        localStorage.setItem('herotag', username);
        localStorage.setItem('isHerotag', true);
        // store.dispatch("erdWallet/setHerotag", username)
      }
      const password = "test";
      store.dispatch("login/login", { username, password })
      .then((user) => {
        store.dispatch("user/getUserData", user, { root: true });
      })
    });
    // simulate login in backend
  }
  if(data.type == "logout") {
    store.dispatch("login/logout");
  }
}

var useGetAccount = function useGetAccount(address) {
  return dapp.proxy.getAccount(new Address(address));
}

var useGetAddress = function useGetAddress() {
  var _useLocation = window.location,
      search = _useLocation.search;

  var loggedIn = localStorage.getItem('loggedIn');
  var address = localStorage.getItem('address');
  var walletLogin = localStorage.getItem('walletLogin');
  var provider = localStorage.getItem('provider');
  dapp.provider = provider;
  var providerType = getProviderType(provider);
  // return function () {
    return providerType && providerType !== "wallet" ? provider.getAddress() : new Promise(function (resolve) {
      if (walletLogin) {
        var urlSearchParams = new URLSearchParams(search);
        var params = Object.fromEntries(urlSearchParams);
        if (addressIsValid(params.address)) {
          console.log('address', params.address);
          localStorage.setItem('walletLogin', false);
          localStorage.setItem('loggedIn', true);
          localStorage.setItem('loginMethod', 'webwallet');
          store.dispatch('erdWallet/setAddress', params.address);
          store.dispatch('erdWallet/login');
          localStorage.setItem('address', params.address);
          store.dispatch('erdWallet/login');
          resolve(params.address);
          dispatch({
            type: "login",
            address: params.address,
            loginMethod: "webwalletconnect"
          });
      }
      if (loggedIn) {
        resolve(address);
      }
      resolve("");
    }
    });
}

function addressIsValid(destinationAddress) {
  var isValidBach = !(!destinationAddress || !destinationAddress.startsWith("erd") || destinationAddress.length !== 62 || /^\w+$/.test(destinationAddress) !== true);
  return isValidBach && canTransformToPublicKey(destinationAddress);
}

function canTransformToPublicKey(address) {
  try {
    var checkAddress = new Address(address);
    return Boolean(checkAddress.bech32());
  } catch (_unused) {
    return false;
  }
}

var getProviderType = function getProviderType(provider) {
  var providerType = "";
  providerType = provider && provider.constructor === WalletProvider ? "wallet" : providerType;
  providerType = provider && provider.constructor === WalletConnectProvider ? "walletconnect" : providerType;
  providerType = provider && provider.constructor === HWProvider ? "ledger" : providerType;
  providerType = provider && provider.constructor === ExtensionProvider ? "extension" : providerType;
  return providerType;
};

var newWalletProvider = function newWalletProvider(walletAddress) {
  return new WalletProvider("" + walletAddress);
};

var handleOnLogin = function handleOnLogin() {

  console.log('handleOnLogin')
  var provider = dapp.provider;
  console.log('provider', provider);
  provider.getAddress().then(function (address) {
    // var loggedIn = !localStorage.getItem("loginMethod");

    // if (!loggedIn) {
    //   history.push(callbackRoute);
    // }

    provider.getSignature().then(function (signature) {
      if (signature) {
        var tokenLogin = localStorage.getItem("tokenLogin");
        var loginToken = tokenLogin && "loginToken" in tokenLogin ? tokenLogin.loginToken : "";
        store.dispatch('erdWallet/setSignature', signature);
        dispatch({
          type: "setTokenLogin",
          tokenLogin: {
            loginToken: loginToken,
            signature: signature
          }
        });
      }
    });

    dispatch({
      type: "setWalletConnectLogin",
      walletConnectLogin: {
        loginType: "walletConnect",
        callbackRoute: callbackRoute,
        logoutRoute: logoutRoute
      }
    });

    store.dispatch('erdWallet/setAddress', address);
    dispatch({
      type: "login",
      address: address,
      loginMethod: "walletconnect"
    });

    localStorage.setItem('walletLogin', false);
    localStorage.setItem('loggedIn', true);
    localStorage.setItem('loginMethod', true);
    localStorage.setItem('address', address);

    store.dispatch('erdWallet/login');

    provider.walletConnector.on("heartbeat", function () {
      clearInterval(heartbeatDisconnectInterval);
      heartbeatDisconnectInterval = setInterval(function () {
        console.log("Maiar Wallet Connection Lost");
        handleOnLogout();
        clearInterval(heartbeatDisconnectInterval);
      }, 150000);
    });
  })["catch"](function (e) {
    // setError("Invalid address");
    console.log('Error', e);
  });
};

var handleOnLogout = function handleOnLogout() {
  store.dispatch('erdWallet/logout');
  store.dispatch("login/logout")
  console.log('handleOnLogout')
};


export const walletConnect = {
  // walletConnectBridge,
  // walletConnectDeepLink,
  // newWalletProvider,
  WalletConnect,
  useWebWalletLogin,
  // WalletLogin,
  useGetAddress,
  useLogout,
  // useInitWalletConnect
};