// import { store } from '../store/store';
import {
  // WalletProvider,
  SmartContract,
  // U32Value,
  // BytesValue,
  GasLimit,
  ContractFunction,
  Account,
  Address,
  // AddressValue,
  ProxyProvider,
  NetworkConfig,
  // TransactionPayload,
  // Transaction,
  Balance,
  UserSecretKey,
  UserSigner,
  // Balance,
  // U64Value,
  // BigUIntValue,
  // ExtensionProvider,
  // AddressValue,
  // SignableMessage,
  // Hash
} from "@elrondnetwork/erdjs";

// import BigNumber from 'bignumber.js';

let tokenIdentifier = "ENV-5c25fe";
let carolPEM = `-----BEGIN PRIVATE KEY for erd1rswrc3q05qr9vxqzzsxnqqksuprpz2kcre02kyjpaxshkd7jpw2srchmev-----
NDkxZjM2MzAwMDBlZTY5NDRkYWU1OGE2ODJhMDc4NmVjZWNmMWFhMzA0Y2I0MGNi
MjhhYmJiNTVjY2YyZjdiNjFjMWMzYzQ0MGZhMDA2NTYxODAyMTQwZDMwMDJkMGUw
NDYxMTJhZDgxZTVlYWIxMjQxZTlhMTdiMzdkMjBiOTU=
-----END PRIVATE KEY for erd1rswrc3q05qr9vxqzzsxnqqksuprpz2kcre02kyjpaxshkd7jpw2srchmev-----`;
let alicePEM = `-----BEGIN PRIVATE KEY for erd1m3q5lax3uk6ucwyuk4mlzdpdhlh24njup0psjdnfkeleqpehckzqktwgkt-----
MDNmNGM4NzhlZWQ4OWRiNTAyOTRmYzBlNGU3ZDY0ZDU4ZDRjODg0OWVlNDYwMmFh
M2FhNTAzZTc2NDBiMzdmNmRjNDE0ZmY0ZDFlNWI1Y2MzODljYjU3N2YxMzQyZGJm
ZWVhYWNlNWMwYmMzMDkzNjY5YjY3ZjkwMDczN2M1ODQ=
-----END PRIVATE KEY for erd1m3q5lax3uk6ucwyuk4mlzdpdhlh24njup0psjdnfkeleqpehckzqktwgkt-----`;

let addressOfAlice = new Address(
  "erd1m3q5lax3uk6ucwyuk4mlzdpdhlh24njup0psjdnfkeleqpehckzqktwgkt"
);
let addressOfCarol = new Address(
  "erd1rswrc3q05qr9vxqzzsxnqqksuprpz2kcre02kyjpaxshkd7jpw2srchmev"
);
let addressOfContract = new Address(
  "erd1qqqqqqqqqqqqqpgq5kyx0cehrhuydhdevuvlp3e52gq5dmx6pw2sc30fvd"
  );
  
  let SellCard = async function SellCard() {
  console.log('SM - SellCard');
  let signer = new UserSigner(UserSecretKey.fromPem(alicePEM));

  let provider = new ProxyProvider("https://testnet-gateway.elrond.com");
  await NetworkConfig.getDefault().sync(provider);

  console.log(NetworkConfig.getDefault().MinGasPrice);
  console.log(NetworkConfig.getDefault().ChainID);

  // let walletAddress  = localStorage.getItem('address');
  let alice = new Account(addressOfAlice);
  let contract = new SmartContract({ address: addressOfContract });

  console.log("contract", contract);
  console.log("addressOfAlice", addressOfAlice);
  console.log("addressOfCarol", addressOfCarol);
  console.log("addressOfContract", addressOfContract);

  let staticPayload2 = `ESDTNFTTransfer@454e562d356332356665@06@01@00000000000000000500162121d3b76c6103ca8ce01ce0df0d2c7587e3fcc584@61756374696f6e546f6b656e@0de0b6b3a7640000@0de0b6b3a7640000@61e2c2a4@7865676c64`;
  // let bid = 1000000000000000000;
  let minbid = 1000000000000000000;
  let maxbid = 1000000000000000000;
  let staticPayload =
    "ESDTNFTTransfer" +
    "@" + toHex(tokenIdentifier) + //<Card identifier in hexadecimal encoding> +
    "@" + "0b" + //<the NFT nonce in hexadecimal encoding> +
    // "@" + toHex("02") + //<the NFT nonce in hexadecimal encoding> +
    "@" + "01" + //<the amount in hexadecimal encoding> +
    // "@" + toHex("1") + //<the amount in hexadecimal encoding>
    "@" + addressOfContract.valueHex +
    "@" + toHex("auctionToken") +
    // toHex("sellCard") +
    // "@" + "61756374696f6e546f6b656e" +
    // "@" + toHex("1000000000000000000") + // {min_bid}
    // "@" + "0de0b6b3a7640000" +
    "@0" + minbid.toString(16) +
    // "@" + toHex("1000000000000000000") + // {max_bid}
    // "@" + "0de0b6b3a7640000" +
    "@0" + maxbid.toString(16) +
    // "@" + toHex("1642250916") + // {deadline}
    "@" + "61e2c2a4" +
    // "@" + "7865676c64" +
    "@" + toHex("EGLD") + // {accepted_payment_token}
    // "@0de0b6b3a7640000@0de0b6b3a7640000@61cf195f@45474C44"
    "";

  let tx = contract.call({
    func: new ContractFunction(staticPayload),
    // gasLimit: new GasLimit(NetworkConfig.getDefault().MinGasPrice + 10000),
    gasLimit: new GasLimit(10000000),
    sender: addressOfAlice,
    receiver: addressOfAlice,
    value: 0,
  });

  await alice.sync(provider);

  console.log(staticPayload);
  console.log(staticPayload2);

  tx.setNonce(alice.nonce);

  console.log("tx", tx);

  await signer.sign(tx);
  await tx.send(provider);

  console.log("tx", tx);

  console.log("tx.hash", tx.hash.toString());
  // check_transaction(hashTX, provider);
};

let BuyCard = async function BuyCard() {
  console.log('SM - BuyCard');
  let signer = new UserSigner(UserSecretKey.fromPem(carolPEM));

  let provider = new ProxyProvider("https://testnet-gateway.elrond.com");
  await NetworkConfig.getDefault().sync(provider);

  let carol = new Account(addressOfCarol);
  await carol.sync(provider);

  let contract = new SmartContract({ address: addressOfContract });

  let staticPayload =
    // "ESDTNFTTransfer" +
    // "@" + toHex(tokenIdentifier) + //<Card identifier in hexadecimal encoding> +
    // "@" + "04" + //<the NFT nonce in hexadecimal encoding> +
    // "@" + toHex("02") + //<the NFT nonce in hexadecimal encoding> +
    // "@" + "01" + //<the NFT nonce in hexadecimal encoding> +
    // "@" + toHex("1") + //<the amount in hexadecimal encoding>
    // "@" + addressOfContract.valueHex +
    // "@" + toHex("bid") +
    // "@" + toHex("1000000000000000000") + // {min_bid}
    // "@" + toHex("1000000000000000000") + // {max_bid}
    // // "@" + toHex("1642250916") + // {deadline}
    // "@" + "61e2c2a4" +
    // "@" + toHex("XeGLD") + // {accepted_payment_token}

    
    // "@" + "626964" +
    // "bid" +
    "endAuction" +
    "@" + "05" + //<value> +
    // "@" + toHex(tokenIdentifier) + //<Card identifier in hexadecimal encoding> 
    // "@" + "0b" + //<the NFT nonce in hexadecimal encoding> +
    "";

  console.log(staticPayload);
  // console.log(staticPayload2);

  let bid = 1000000000000000000;
  let Bbid = new Balance(bid);

  let tx = contract.call({
    func: new ContractFunction(staticPayload),
    gasLimit: new GasLimit(10000000),
    sender: addressOfCarol,
    receiver: addressOfContract,
    // value: bid,
    value: 0,
    // value: Balance.egld(1),
  });

  tx.setNonce(carol.nonce);

  console.log("tx", tx);
  console.log("bid", bid.toString(16));
  console.log("signer", signer);
  console.log("Bbid", Bbid);

  await signer.sign(tx);
  let hashTX = await tx.send(provider);

  console.log("tx", tx);
  console.log("tx.hash", hashTX.toString());
  check_transaction(hashTX, provider);
  
};

function check_transaction(hashTX, provider){
  setTimeout( async function(){
    if( await provider.getTransactionStatus(hashTX).isExecuted()  == false) {
      console.log('waiting...');
        check_transaction(hashTX, provider);
      } else  {
        console.log('transaction done');
      }
  }, 1000);
}

function toHex(str) {
  var arr1 = [];
  for (var n = 0, l = str.length; n < l; n++) {
    var hex = Number(str.charCodeAt(n)).toString(16);
    arr1.push(hex);
  }
  return arr1.join("");
}

export const smartContract = {
  SellCard,
  BuyCard,
};
