const state = {
    name: '',
    active: false,
}

const getters = {
    currentBtn: state => {
        return state.name;
    },
    active: state => {
        return state.active;
    }
}

const actions = {
    getCurrent({ commit }) {
        commit('setCurrent');
    }
}

const mutations = {
    setCurrent(state) {
        // console.log('merge');
        state.name = 'Currently Owning';
    },
    setActive(state) {
        state.active = true;
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};