import axios from 'axios';
import { authHeader } from '../../_helpers/auth-header';
import { globalVars } from './globalVars'
import { userService } from '../../_services';

const state = {
    rank: [],
    userRank: [],
}
const api = globalVars.state.api

const getters = {
    rank: state => {
        return state.rank;
    },
    userRank: state => {
        return state.userRank;
    }
}

const actions = {
    getRank({ commit }) {
        axios
            .get(`${api.url}/api/standings`, {
                headers: authHeader(),
                params: {
                    limit: 10,
                    offset: 0
                }
            })
            .then(response => response.data)
            .then(object => {
                commit('setRank', object.success);
            })
            .catch(error => console.log(error))
    },

    getUserRank({ commit }) {

        var userRank = userService.getUserData();
        console.log(userRank);
        
        commit('setUserRank', userRank)
    //     axios
    //         .get('https://178.18.251.150/api/user/rank', {
    //             headers: authHeader(),
    //             params: {
    //                 user_id: user_id
    //             }
    //         })
    //         .then(response => response.data)
    //         .then(object => {
    //             commit('setUserRank', object.success)
    //         })
    //         .catch(error => console.log(error))
    },
    

    async getUserData({ commit, user }) {

        console.log(user);
        var user_data
        axios
            .get(`${api.url}/api/user/auth`, {
                headers: authHeader(),
            })
            .then(response => response.data)
            .then(object => {
                user_data = object.success
                console.log(user_data)
                commit('setData', user_data)
                return user_data
            })
    }
}

const mutations = {
    setRank(state, object) {
        state.rank = object;
    },
    setUserRank(state, object) {
        state.userRank = object;
    },
    setData(state, object) {
        state.userdata = object;
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};