export const globalVars = {
    namespaced: true,
    state: {
        api: {
            url: 'https://178.18.251.150'
        }
    },
    actions: {
    },
    mutations: {
    }
}