<template>
    <div class="map-parag-wrapper">
        <p class="map-f-paragraph">Countdown to the next airdrop</p>
        <p class="map-s-paragraph">{{ formattedTimeLeft }}</p>
    </div>
</template>

<script>
// var numeral = require("numeral");
import { mapActions } from "vuex";

export default {
    name: "mapCountdown",
    components: {},
    // data() {
    //   return {
    //     nextAirDrop: airdrop.state.nextAirDrop,
    //     // urlPath: this.$route.path,
    //   };
    // },
    data() {
        return {
            countDown: this.$store.getters["airdrop/nextAirDrop"],
        };
    },
    computed: {
        ...mapActions("airdrop", ["checkNextAirDrop"]),
        formattedTimeLeft() {
            // var timeLeft = this.countDown = nextAirDrop()
            var timeLeft = this.countDown;

            const days = Math.floor(timeLeft / (60 * 60 * 24));
            timeLeft = timeLeft - days * 60 * 60 * 24;
            const hours = Math.floor(timeLeft / (60 * 60));
            timeLeft = timeLeft - hours * 60 * 60;

            // The largest round integer less than or equal  to the result of time divided being by 60.
            const minutes = Math.floor(timeLeft / 60);
            // Seconds are the remainder of the time divided by 60 (modulus operator)
            let seconds = timeLeft % 60;
            // If the value of seconds is less than 10, then display seconds with a leading zero
            if (seconds < 10) {
                seconds = `0${seconds}`;
            }
            // The output in MM:SS format
            return `${days}d ${hours}h ${minutes}m ${seconds}s`;
        },
    },
    beforeCreate() {
        this.checkNextAirDrop;
    },
    created() {
        this.checkNextAirDrop;
    },

    watch: {
        countDown: {
            handler(value) {
                if (value > 0) {
                    setTimeout(() => {
                        this.countDown--;
                    }, 1000);
                }
            },
            immediate: true, // This ensures the watcher is triggered upon creation
        },
    },
};
</script>

<style>
@import "../../assets/css/map.css";

.map-parag-wrapper {
    position: absolute;
    bottom: 40px;
    width: 100vw;
    /* height: 100vh; */
    top: auto;
}
</style>
