<template>
  <div id="app">
    <the-header></the-header>
    <router-view class="view-wrapper" />
    <the-footer></the-footer>
  </div>
</template>

<script>
import TheHeader from "@/components/layouts/mainHeader.vue";
import TheFooter from "@/components/layouts/footerSection.vue";


export default {
  name: "App",
  components: {
    TheHeader,
    TheFooter,
  },
};

</script>

<style>
/* .view-wrapper {
  background: linear-gradient(328.82deg, #26272b 22.72%, #34393f 89.31%);
} */

#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  /* margin-top: 60px; */
  min-height: 100vh;
  background: linear-gradient(328.82deg, #26272b 22.72%, #34393f 89.31%);
}

body {
  font-family: "Josefin Sans", sans-serif;
  margin: 0px;
}

h3 {
  margin: 40px 0 0;
}

button {
  cursor: pointer;
}

button.black,
.button.black {
  border: none;
  text-align: center;
  padding: 8px;
  color: #c75300;
  background: linear-gradient(130.46deg, #26272b 12.58%, #34393f 93.19%);
  box-shadow: -5px -5px 20px #40464e, 4px 3px 19px #0d0e0f;
  border-radius: 20px;
  padding: 10px 20px;
  line-height: 22px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

button.black:hover,
.button.black:hover {
  box-shadow: inset -3px -3px 10px #40464e, inset 3px 3px 10px #0d0e0f;
}

button.caro,
.button.caro {
  background: linear-gradient(130.46deg, #26272b 12.58%, #34393f 93.19%);
  box-shadow: -5px -5px 20px #40464e, 4px 3px 19px #0d0e0f;
  border-radius: 6px;
  transform: rotate(-45deg);
  width: 43.75px;
  height: 43.75px;
}

/* remove blue outline */
button:focus {
  outline: 0;
}


.popup-close {
    width: 25px;
    height: 25px;
    background: linear-gradient(130.46deg, #26272B 12.58%, #34393F 93.19%);
    box-shadow: -5px -5px 20px #40464E, 4px 3px 19px #0D0E0F;
    border-radius: 50%;
    position: absolute;
    color: #fff;
    padding-left: 3px;
    padding-top: 4px;
    font-size: 20px;
    cursor: pointer;
    right: 10px;
    margin-top: 10px;
    top: 0;
    text-align:center;
}

.popup-close:hover {
    background: linear-gradient( 137.63deg, #26272b 9.53%, #34393f 95.92%);
    box-shadow: inset -3px -3px 10px #40464e, inset 3px 3px 10px #0d0e0f;
}


.sort-show-wrapper .vs__dropdown-menu li:nth-child(2), .droptown-content span:nth-child(2) a{
    color: #7266FF !important;
}

.sort-show-wrapper .vs__dropdown-menu li:nth-child(3), .droptown-content span:nth-child(3) a {
    color: #00ffe0 !important;
}

.sort-show-wrapper .vs__dropdown-menu li:nth-child(4), .droptown-content span:nth-child(4) a {
    color: #ffd130 !important;
}

.sort-show-wrapper .vs__dropdown-menu li:nth-child(5), .droptown-content span:nth-child(5) a {
    color: #a6a6a6 !important;
}

.sort-show-wrapper .vs__dropdown-menu li:nth-child(6), .droptown-content span:nth-child(6) a {
    color: #cd7f32 !important;
}

</style>
