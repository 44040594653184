<template>
  <div>
    <div class="guide-wrapper">
      <h2>eEarth Guide</h2>
      <button id="mdiv" class="close-btn" @click="closeGuide()">
        <div class="mdiv">
          <div class="md"></div>
        </div>
      </button>
      <div class="guide-text-wrapper">
        <p>Last Update - Dex 8th, 2020 at 9:00 PM PST</p>
        <p>For tutorials, visit - https://help.alparca.city/en/</p>
        <p>Table of contents</p>
        <p>Bla bla</p>
        <p>Bla bla Bla</p>
        <p>Bla bla</p>
        <p>Bla bla</p>
        <p>Bla bla Bla</p>
        <p>Bla bla</p>
        <p>Bla bla</p>
        <p>Last Update - Dex 8th, 2020 at 9:00 PM PST</p>
        <p>For tutorials, visit - https://help.alparca.city/en/</p>
      </div>
      <div class="checkbox-wrapper checkbox-border">
        <div class="checkbox-one">
          <input
            type="checkbox"
            class="connect-checkbox"
            id="checkbox"
            required
            @click="check()"
          />
          <label for="checkbox" class="first"></label>
          <label for="checkbox" class="first-text">I have read the Guide and I know the implications in using the app.</label>
        </div>
        <!-- <p class="checkbox-txt">
          I have read the Guide and I know the implications in using the app.
        </p> -->
      </div>
      <div class="connect-btn-wrapper">
        <button :class="{disabled: checked == false}" @click="connectTo()" class="connect-wallet-btn first-step-btn">
          Connect Maiar
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Guide",
  data() {
    return {
      checked: false,
    };
  },
  methods: {
    check() {
      if (this.checked == false) {
        this.checked = true;
      } else if (this.checked == true) {
        this.checked = false;
      }
    },
    connectTo() {
      if(this.checked == true) {
        this.$store.dispatch("connect/connectTo");
        this.name = "Connecting...";
        setTimeout(function () {
          window.location.href = "/login";
        }, 1000);
      }
    },
  },
  props: ["closeGuide"],
};
</script>

<style>
@import "../../assets/css/introduction.css";
@import "../../assets/css/connect.css";
</style>