<template>
  <div>
    <div class="title-collectibles">My Collectibles</div>
    <div>
      <div class="filter-wrapper">
        <button
          v-on:click="currentBtn"
          v-bind:class="[activeBtn === 1 ? 'active' : '']"
        >
          Currently Owning
        </button>
        <!-- <button
          v-on:click="activeBtn = 2"
          v-bind:class="[activeBtn === 2 ? 'active' : '']"
          @click="openFilter"
        >
          Filter
        </button> -->
      </div>
      <div class="carousel-wrapper">
        <card-carousel />
      </div>
      <!-- <div class="coll-filter-wrapper">
        <coll-filter v-if="filter == true" :close="closeFilter" />
      </div> -->
    </div>
  </div>
</template>

<script>
import cardCarousel from "@/components/carousel/cardCarousel.vue";
// import collFilter from "@/components/collectibles/Filter.vue";
import { mapActions } from "vuex";

export default {
  name: "Collectibles",
  components: {
    cardCarousel,
    // collFilter,
  },
  data() {
    return {
      filter: false,
      activeBtn: 1,
    };
  },
  methods: {
    openFilter() {
      this.filter = true;
    },
    closeFilter() {
      this.filter = false;
      this.activeBtn = 1;
    },
    currentBtn() {
      this.activeBtn = 1;
      this.filter = false;
    },
  },

  computed: {
    ...mapActions("cards", ["getCards"]),
    ...mapActions("cards", ["getAvailableCards"]),
  },
  created() {
    this.getCards;
    this.getAvailableCards;
    // this.$store.dispatch('cards/getCards');
    // this.$store.dispatch('cards/getAvailableCards');
  },
};
</script>

<style>
@import "../../assets/css/collectibles.css";
</style>