<template>
    <div class="mapWraper">
        <div class="mapWraperContainer" v-if="isAvailable">
            <MapContainer
                :geojson="geojson"
                v-on:select="selected = $event"
            ></MapContainer>
        </div>
        <MapCardWrapper></MapCardWrapper>
        <MapCountdown :feature="selected"></MapCountdown>
        <mapCountryList></mapCountryList>
        <loading :active="!isAvailable" />
    </div>
</template>

<script>
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import MapContainer from "@/components/map/MapContainer";
import MapCountdown from "@/components/map/mapCountdown";
import mapCountryList from "@/components/map/mapCountryList";
import MapCardWrapper from "@/components/map/mapCardWrapper";
import json from "@/json/countries.json";
import { mapState, mapActions } from "vuex";

export default {
    name: "Map",
    components: {
        MapContainer,
        MapCountdown,
        mapCountryList,
        MapCardWrapper,
        Loading,
    },
    data: () => ({
        selected: undefined,
        geojson: json,
    }),
    computed: {
        ...mapState("mapCards", ["isCardShow"]),
        ...mapActions("cards", ["getCards"]),
        isCardShow() {
            return this.isCardShow;
        },
        isAvailable() {
            let available = this.$store.getters["cards/marketplaceCards"];
            if (available.length > 0) {
                return true;
            } else {
                return false;
            }
            // return true;
        },
    },
    created() {
        this.getCards;
    },
};
</script>

<style>
.mapWraperContainer {
    width: 100vw;
    height: 100vh;
}

.mapInspector {
    width: 100vw;
    height: 100px;
    position: fixed;
    bottom: 0;
    background-color: rgba(255, 255, 255, 0.7);
}

.mapWraper {
    position: relative;
    width: 100vw;
    height: calc(100vh - 80px);
}
.cell {
    border-radius: 4px;
    background-color: lightgrey;
}
.cell-map {
    grid-column: 1;
    grid-row-start: 1;
    grid-row-end: 3;
}
.cell-edit {
    grid-column: 2;
    grid-row: 1;
}
.cell-inspect {
    grid-column: 2;
    grid-row: 2;
}
</style>
