<template>
  <div>
    <button class="search-filter" @click="openFilter">Search Filter</button>
    <div class="market-filter-wrap">
      <market-filter
        v-if="filter == true"
        :class="{ activeF: filter == true }"
        :close="closeFilter"
        :Sort="selectedSort"
        @change="logChange"
      />
    </div>
    <div>
      <div class="market-cards-wrapper">
        <div class="asd">
          <template>
          </template>
          <template>
            <card-map
              class="card-wrap marketplace-page"
              v-for="(card, index) in sort"
              :key="index"
              :card="card"
            />
          </template>
          <subcateg class="subcateg" />
        </div>
      </div>
    </div>
    <!-- popup card -->
    <div class="mapCount-cards popup-cards-wrp" v-if="isCardShow">
      <card-element class="card-wrap" v-for="card in popupCard" :key="card.id" :card="card" />
    </div>
  </div>
</template>

<script>
import marketFilter from "@/components/collectibles/Filter.vue";
import cardMap from "@/components/carousel/cardMap";
import cardElement from "@/components/cards/cardElement";
import Subcateg from "@/components/market/Subcateg.vue";
import { mapActions, mapState, mapMutations } from "vuex";

export default {
  name: "Marketplace",
  components: {
    marketFilter,
    cardMap,
    cardElement,
    Subcateg,
  },
  data() {
    return {
      filter: false,
      urlPath: this.$route.path,
      selectedSort: ""
    };
  },
  methods: {
    openFilter() {
      this.filter = true;
    },
    closeFilter() {
      this.filter = false;
    },
    logChange (event) {
        this.selectedSort = event;
      }
  },
  computed: {
    available() {
      return this.$store.getters["cards/availableCards"];
    },
    sort() {
      return this.$store.getters["cards/filterCards"];
    },
    popupCard() {
      return this.$store.getters["cards/countryCards"];
    },
    ...mapState("mapCards", ["isCardShow"]),
    ...mapActions("cards", ["getCards"]),
    ...mapMutations("mapCards", ["hideCards"]),
  },

  created() {
    this.getCards;
    this.getAvailableCards;
  },
};
</script>

<style>
@import "../../assets/css/responsive.css";
.mapCount-cards.popup-cards-wrp, .popup-cards-wrp{
  position: fixed;
  z-index: 10;
}
</style>